import React from "react";
import HomeContactUs from "../components/HomeContactUs";

const Contact = () => {
  return (
    <>
      <div>
        <HomeContactUs />
      </div>
    </>
  );
};

export default Contact;
