import React from "react";
import { AiTwotoneEdit } from "react-icons/ai";
import { BsFillCameraFill } from "react-icons/bs";
import image from "../../assets/istockphoto-1352396871-1024x1024.jpg";
import { Link } from "react-router-dom";
import { MdModeEditOutline } from "react-icons/md";
import { useAdminProfileQuery } from "../../services/profile";
import { getToken } from "../../services/localStorage";
import logo from "../../assets/KVK Logo.png";

const AdminProfilePage = () => {
  const token = getToken("token");
  const { data, isLoading } = useAdminProfileQuery(token);
  console.log(data, "13");

  return (
    <>
      {/* {isLoading?"...loding":}
    <div>
    </div> */}
      <div className="text-white bg-Hero bg-no-repeat  h-80 bg-cover relative mb-28">
        <button className="bg-gray-500 p-2 rounded-full absolute right-4 bottom-4 border-2">
          <BsFillCameraFill className="text-2xl text-black  " />
        </button>
        <div className="absolute bottom-0  left-12 translate-y-1/2">
          <div className="relative">
            <img
              className="h-40 w-40 rounded-full object-contain p-2 bg-black/80 border-4 border-white"
              src={logo}
              alt=""
            />
            <button className="bg-gray-500 p-2 rounded-full absolute bottom-6 -right-3 border-2">
              <BsFillCameraFill className="text-2xl text-black  " />
            </button>
          </div>
        </div>
      </div>

      {/*  */}
      <div className="w-10/12 m-auto grid bg-admin-primary text-white justify-items-center p-4 rounded-3xl">
        <div className="flex justify-between border-b p-2 w-full text-light">
          <h3>Account</h3>
          <Link to="/admindashboard/changegmail">
            <span>
              <MdModeEditOutline className="text-3xl" />
            </span>
          </Link>
        </div>
        <div className="flex justify-between border-b p-2 w-full text-light">
          <span>Username</span>
          <span>{data?.name}</span>
        </div>
        <div className="flex justify-between border-b p-2 w-full text-light">
          <span>Email</span>
          <span>{data?.email}</span>
        </div>
        <div className="flex justify-between border-b p-2 w-full text-light">
          <span>Phone Number</span>
          <span>{data?.phoneNumber}</span>
        </div>
      </div>
    </>
  );
};

export default AdminProfilePage;
