import React from "react";
import { TbRobotOff } from "react-icons/tb";
import { FaHome } from "react-icons/fa";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";

const PageNotFound = () => {
  const navigate = useNavigate();

  const goback = () => {
    navigate(-1);
  };

  return (
    <>
      <div className="w-full h-screen grid items-center justify-items-center bg-[#363676]">
        <div className="grid sm:grid-cols-3  text-white items-center justify-items-center w-11/12 m-auto p-4 grid-cols-1 gap-4">
          <div className="col-span-2 grid justify-items-center gap-2">
            <h2 className="text-[100px]">404</h2>
            <p className="text-3xl">Sorry , the page not found</p>
            <p className="text-center">
              The link you followed probably broken or the page has been removed
            </p>
            <Link
              onClick={goback}
              className="flex px-4 py-2 rounded text-primary bg-text_blue items-center gap-3 hover:scale-105 duration-300"
            >
              Go Home <FaHome className="text-2xl text-primary" />
            </Link>
          </div>
          <div>
            <TbRobotOff className="sm:text-[200px] text-[50px] text-red-600" />
          </div>
        </div>
      </div>
    </>
  );
};

export default PageNotFound;
