import React from "react";
import HeroSection from "../components/HeroSection";
import HomeBasic from "../components/HomeBasic";
import HomeAboutUs from "../components/HomeAboutUs";
import HomeContactUs from "../components/HomeContactUs";
import Advantages from "../components/Advantages";
import DecodingSuccess from "../components/DecodingSuccess";

const HomePage = () => {
  return (
    <>
      <HeroSection />
      <HomeBasic />
      <Advantages />
      <HomeAboutUs />
      <DecodingSuccess />
      <HomeContactUs />
    </>
  );
};

export default HomePage;
