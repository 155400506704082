import React, { useState } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import FormikControl from "../../components/ReusableFormField/FormikControl";
import { Link } from "react-router-dom";
import { useAddBarcodeMutation } from "../../services/profile";
import { getToken } from "../../services/localStorage";
import { ToastContainer, toast } from 'react-toastify';


const AdminQRCodeUpdate = () => {
  const token = getToken('token');
  const [editQR] =useAddBarcodeMutation()
  const [formKey, setFormKey] = useState(0);
  const [post, setPost] = useState(null);

  const initialValues = {
    qrCode: null,
    upiId: "",
  };
  const onSubmit = async(values, onSubmitProps) => {
    console.log("From Data", values);
    const value = new FormData();
    value.append("qrcode", values.qrCode);
    value.append("upiId", values.upiId);
    const res = await editQR({value,token})
    console.log(res,"24")
    toast(res.data.message)
    onSubmitProps.setSubmitting(false);
    onSubmitProps.resetForm();
    setFormKey((prevKey) => prevKey + 1);
  };
  const validationSchema = Yup.object({
    qrCode: Yup.mixed()
      .required("Image is required")
      .test("fileType", "Invalid file format", (value) => {
        if (!value) return true; // Allow empty values (e.g., if the field is not required)
        return (
          value && ["image/jpeg", "image/png", "image/gif"].includes(value.type)
        );
      })
      .test("fileSize", "File size too large", (value) => {
        if (!value) return true; // Allow empty values (e.g., if the field is not required)
        return value && value.size <= 5242880; // 5 MB
      }),
    upiId: Yup.string()
      .required("UPI ID is required")
      .matches(/^[\w.-]+@[\w.-]+$/, "Invalid UPI ID format"),
  });

  return (
    <>
    <ToastContainer />

      <div className="bg-white_background shadow-xl border  shadow-black  p-4 rounded-md md:w-7/12 sm:w-10/12 m-auto">
        <div className="flex  justify-between items-center border-b border-black/30 my-3 flex-col sm:flex-row p-2">
          <h3>Update Your QR Code </h3>
        </div>

        {/* Form */}
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={validationSchema}
          validateOnMount
        >
          {(formik) => {
            console.log("formik", Formik);
            setPost(formik.values.qrCode);
            return (
              <Form>
                <div className="grid justify-items-center">
                  {post && (
                    <img
                      className="w-40 h-40   object-cover"
                      src={URL.createObjectURL(post)}
                      alt="img"
                    />
                  )}
                </div>
                <div className="grid grid-cols-1 gap-6">
                  {/* Image */}
                  <FormikControl
                    control="image"
                    label="QRCode Image"
                    name="qrCode"
                    key={formKey}
                    valid={formik.errors.qrCode && formik.touched.qrCode}
                  />

                  <FormikControl
                    control="input"
                    type="text"
                    label="UPI ID"
                    name="upiId"
                    valid={formik.errors.upiId && formik.touched.upiId}
                    placeholder="eg ;  ******4567@ybl"
                  />

                  <button
                    className="px-4 py-2 rounded bg-text_blue active:text-white disabled:bg-red-500 disabled:cursor-not-allowed"
                    disabled={!formik.isValid || formik.isSubmitting}
                    type="submit"
                  >
                    Submit
                  </button>
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    </>
  );
};

export default AdminQRCodeUpdate;
