import React from "react";
import { FaThumbsUp } from "react-icons/fa6";
import { FaThumbsDown } from "react-icons/fa";
import { MdOutlineRecommend } from "react-icons/md";
import { TbTargetArrow } from "react-icons/tb";
import { FaSquareWhatsapp } from "react-icons/fa6";

// import StopLoss from "../assets/stop-loss-order (1).png";
import ProfitTarget from "../assets/profit target.jpg";
import IntraDay from "../assets/intraday trading cash.jpg";
import WhatYouGet from "../assets/what you get.jpg";
import stopLoss from "../assets/stop loss.jpg";
import Resposibly from "../assets/trade responsibly.jpg";
import Break from "../assets/take breaks.jpg";
const CashService = () => {
  return (
    <>
      <div className="rounded-bl-full md:w-10/12 m-auto w-full">
        {/* first Portion */}
        <div className="bg-[#302452] p-4 shadow-2xl shadow-black grid gap-2 my-16">
          <div className="border border-green-700 bg-green-700 h-1 rounded-full w-7 "></div>
          <h3>Cash Service</h3>
          <h2 className="text-green-500 text-xl "> In the share market,</h2>
          <p className="font-light">
            Cash is the money available to buy or sell shares. It is also known
            as "free cash" or "available funds." Cash is important for investors
            because it enables them to seize opportunities as they arise. For
            example, if an investor identifies an undervalued stock, they can
            use their cash to purchase it. Cash can also be utilized to offset
            losses in case of market declines.
          </p>
        </div>

        {/* Second Portion */}
        <div className="bg-black/70 border border-slate-300/20 p-2 text-white shadow-2xl shadow-black my-16">
          <div className="grid md:grid-cols-3 grid-cols-1 items-center">
            <div className="col-span-2 p-2 grid gap-3">
              <h3>Intraday trading in cash</h3>
              <p className=" font-light text-white/90">
                Intraday stock cash refers to cash used to buy and sell stocks
                within the same trading day. This type of trading, known as
                intraday trading, is popular among day traders who aim to profit
                from short-term price movements in the market.
              </p>
              <p className=" font-light text-white/90">
                When you engage in intraday trading, you essentially borrow
                money from your broker to buy stocks. This is because you are
                not purchasing the stocks outright; instead, you are speculating
                on whether the stock's price will rise or fall within the day.
                If the price increases, you can sell the stock for a profit.
                Conversely, if the price decreases, you will incur losses.
              </p>
              <p className=" font-light text-white/90">
                While intraday trading can offer significant rewards, it also
                carries substantial risks. Successful timing of the market can
                lead to substantial profits within a short timeframe. However,
                without careful consideration, you may incur significant losses
                rapidly.
              </p>
            </div>
            <div className="order-first md:order-none rounded-2xl">
              <img
                className="rounded-2xl h-full"
                src={IntraDay} //c4.wallpaperflare.com/wallpaper/663/58/348/money-ipad-retina-wallpaper-preview.jpg"
                alt=""
              />
            </div>
          </div>
        </div>

        {/* Pros and cons */}
        <div className="flex flex-col gap-10 min-h-[50vh] bg-black/70 p-8 md:py-20  ">
          <h3 className="text-center">
            Here are some of the pros and cons of intraday trading
          </h3>
          <div className="grid md:grid-cols-2 grid-cols-1 gap-6">
            <div className="flex flex-col justify-start items-center gap-3 border  rounded-xl p-4 bg-ternary border-slate-300/20 hover:scale-105 duration-300">
              <span>
                <FaThumbsUp className="text-3xl text-green-500" />
              </span>
              <span className="text-xl font-bold ">Pros</span>
              <div>
                <ul className="flex flex-col justify-center items-center md:items-start gap-4 font-light">
                  <li> * Potential for high profits.</li>
                  <li>
                    * Ability to take advantage of short-term price movements.
                  </li>
                  <li>
                    * Leverage (borrowing money from your broker to buy stocks).
                  </li>
                  <li>
                    * Flexibility (you can trade stocks at any time during the
                    trading day).
                  </li>
                </ul>
              </div>
            </div>
            <div className="flex flex-col justify-center items-center gap-3 border  rounded-xl p-4 bg-ternary border-slate-300/20 hover:scale-105 duration-300">
              <span>
                <FaThumbsDown className="text-3xl text-red-500" />
              </span>
              <span className="text-xl font-bold ">Cons</span>
              <div>
                <ul className="flex flex-col justify-center items-center md:items-start gap-4 font-light">
                  <li> * High risk.</li>
                  <li>
                    * Commissions (fees charged by your broker for each trade).
                  </li>
                  {/* <li>
                    * Slippage (the difference between the price you expect to
                    pay for a stock and the price you actually pay).
                  </li> */}
                  <li>
                    *Slippage (the difference between the price you expect to
                    pay for a stock and the price you actually pay).
                  </li>
                  <li>
                    * Margin requirements (the amount of money you must have in
                    your account to support your trades).
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        {/*Tips  */}
        <div className="flex flex-col gap-4 justify-center my-10 bg-admin-primary p-5 rounded-md">
          <h3 className=" font-medium font-subheading">
            Here are some <span className="text-text_blue font-bold">tips</span>{" "}
            for intraday trading
          </h3>
          <p className=" font-body">
            If you are considering intraday trading, it is important to do your
            research and understand the risks involved. You should also make
            sure that you have a good understanding of the stocks you are
            trading and the market as a whole.
          </p>

          <div>
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-5 py-4 z-0">
              <div
                className=" relative border border-gray-500/50 rounded-lg shadow-md flex flex-col
                       justify-center items-center gap-12 overflow-hidden"
              >
                <img src={stopLoss} className="  w-full h-72" />

                <div
                  className="  absolute top-0 left-0 right-0 w-full h-full opacity-0 hover:opacity-[80%] z-10 
            bg-black  justify-center items-center  duration-300 flex flex-col gap-3"
                >
                  <div className="text-sm px-4  text-center text-white">
                    <h5 className=" text-white">Tip 1</h5>
                    <p className="text-white italic">
                      Use a stop-loss order. This is an order that will
                      automatically sell your stock if it falls below a certain
                      price. This will help you limit your losses if the stock
                      price goes against you.
                    </p>
                  </div>
                </div>
              </div>

              <div
                className=" relative border 
             border-gray-500/50 rounded-lg shadow-md flex flex-col justify-center items-center gap-12
                 overflow-hidden"
              >
                <img src={ProfitTarget} className="  w-full h-72" />

                <div
                  className="  absolute top-0 left-0 right-0 w-full h-full opacity-0 hover:opacity-[80%] z-10 
            bg-black  justify-center items-center  duration-300 flex flex-col gap-3"
                >
                  <div className="text-sm px-4  text-center text-white">
                    <h5 className=" text-white">Tip 2</h5>
                    <p className="text-white italic">
                      Use a profit-target order. This is an order that will
                      automatically sell your stock if it reaches a certain
                      price. This will help you lock in your profits if the
                      stock price goes in your favor.
                    </p>
                  </div>
                </div>
              </div>

              <div
                className=" relative border 
             border-gray-500/50 rounded-lg shadow-md flex flex-col justify-center items-center gap-12
                 overflow-hidden"
              >
                <img src={Resposibly} className="  w-full h-72" />

                <div
                  className="  absolute top-0 left-0 right-0 w-full h-full opacity-0 hover:opacity-[80%] z-10 
            bg-black  justify-center items-center  duration-300 flex flex-col gap-3"
                >
                  <div className="text-sm px-4  text-center text-white">
                    <h5 className=" text-white">Tip 3</h5>
                    <p className="text-white italic">
                      Don't trade with more money than you can afford to lose.
                    </p>
                  </div>
                </div>
              </div>

              <div
                className=" relative border 
             border-gray-500/50 rounded-lg flex flex-col justify-center items-center gap-12 shadow-md
                 overflow-hidden"
              >
                <img src={Break} className="  w-full h-72" />

                <div
                  className="  absolute top-0 left-0 right-0 w-full h-full opacity-0 hover:opacity-[80%] z-10 
            bg-black  justify-center items-center  duration-300 flex flex-col gap-3"
                >
                  <div className="text-sm px-4  text-center text-white">
                    <h5 className=" text-white">Tip 4</h5>
                    <p className="text-white italic">
                      Take breaks. Intraday trading can be very stressful, so it
                      is important to take breaks throughout the day.
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <p className=" font-body text-center">
              Intraday trading can be a great way to make money, but it is
              important to remember that it is also a very risky proposition. If
              you are not careful, you can lose a lot of money very quickly.
            </p>
          </div>
        </div>

        {/* KVK Investment Advice */}
        <div className="bg-[#302452] p-4 shadow-2xl shadow-black grid gap-2 my-16">
          <div className="border border-green-700 bg-green-700 h-1 rounded-full w-7 "></div>
          <h3>KVK Investment advice</h3>
          <p className="font-light">
            In this service, Intraday Cash, we provide investment advice calls
            in the equity cash market (NSE). We shall provide 1-2 intraday
            buying/selling/hold stock calls. These calls depend on the equity
            market movement, risk, and number of calls may increase or decrease
            as per stock market behavior, such as very risky, range-bound, or
            sideways movement depending on buyers, sellers, news, and economic
            conditions. These calls are generated after technical and
            fundamental analysis, visualization, screener, charts, market
            movement using candlestick study, trends, patterns, price movement,
            and technical indicators like MACD, RSI, stochastic, ADX/DMS,
            Bollinger Bands, Pivot Points, etc.
          </p>
        </div>

        {/* What You Get */}
        <div className=" space-y-6 py-5 bg-black text-white/70 p-4 rounded-2xl">
          <div className=" grid grid-cols-1 md:grid-cols-2 gap-12 min-h-[75vh] items-center  ">
            <div className=" space-y-8 ">
              <h5 className=" text-5xl font-heading text-secondary">
                What you Get?
              </h5>
              <p className="leading-7 font-body">
                We advise clients not to invest their entire amount in a single
                call. It is recommended to diversify your investment equally
                across each call. We provide 1-2 NSE cash market recommendation
                calls, each with 1-2-3 targets and 1 stop loss. Clients are
                urged to strictly adhere to the stop loss.
              </p>

              <ul className="space-y-1  font-subheading">
                <li>1. Daily news and updates about market activities.</li>
                <li> 2. Follow-up messages for the calls.</li>
                <li> 3. Nifty reviews and support/resistance updates.</li>
                <li>
                  4. Updates on world markets, SGX Nifty, and other relevant
                  information.
                </li>
                <li>
                  5. Featured stocks, Nifty, and Bank Nifty trends, as well as
                  support and resistance levels.
                </li>
              </ul>
            </div>

            <div className="order-first md:order-none ">
              <img className="h-full rounded-lg" src={WhatYouGet} />
            </div>
          </div>

          <div className=" grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-5 min-h-[40vh] ">
            <div
              className=" bg-pink-700 rounded-xl flex flex-col gap-3 justify-center items-center p-7 shadow-md
               hover:scale-105 duration-300"
            >
              <MdOutlineRecommend className=" text-6xl" />
              <p className=" text-center">
                In the intraday stock cash service, only one recommendation is
                provided daily.
              </p>
            </div>

            <div
              className=" bg-green-600 rounded-xl flex flex-col gap-3 justify-center items-center p-7 shadow-md
               hover:scale-105 duration-300"
            >
              <TbTargetArrow className=" text-6xl" />
              <p className=" text-center">
                The first target is set at 2%, the second target at 4%, and the
                stop loss at 2%.
              </p>
            </div>

            <div
              className=" bg-blue-700 rounded-xl flex flex-col gap-3 justify-center items-center p-7 shadow-md
               hover:scale-105 duration-300"
            >
              <FaSquareWhatsapp className=" text-6xl" />
              <p className=" text-center">
                Intraday stock cash recommendations are sent to customers via
                SMS or WhatsApp.
              </p>
            </div>
          </div>

          {/* <div className=" grid grid-cols-1 md:grid-cols-3   gap-5 ">
            <div
              className=" relative border border-white/25   rounded-lg hover:scale-105 duration-500  shadow-md  *
              "
            >
              <img
                src="https://wallpapers.com/images/hd/trading-wallpaper-ynfqhj74ml8p96ca.jpg"
                className=" h-[40vh] w-full rounded-lg"
              />
              <div className=" absolute bottom-0  bg-black/60 hover:bg-black/15 duration-300 py-6 px-2">
                <p className=" font-subheading text-white">
                  In intraday stock cash service only one recommendations are
                  provided daily .
                </p>
              </div>
            </div>

            <div
              className=" relative border border-white/25  duration-500
                   rounded-lg hover:scale-105  shadow-md 
              "
            >
              <img
                src="https://wallpapers.com/images/hd/trading-wallpaper-ynfqhj74ml8p96ca.jpg"
                className=" h-[40vh] w-full rounded-lg"
              />
              <div className=" absolute bottom-0  py-6 px-2 bg-black/60 hover:bg-black/20 duration-300">
                <p className=" font-subheading text-white">
                  In intraday stock cash service, the first target is 2% and the
                  second target is 4% and the stop loss is 2%.
                </p>
              </div>
            </div>

            <div
              className=" relative border border-white/25 
                 hover:scale-105  shadow-md rounded-lg duration-500
              "
            >
              <img
                src="https://wallpapers.com/images/hd/trading-wallpaper-ynfqhj74ml8p96ca.jpg"
                className=" h-[40vh] w-full rounded-lg"
              />
              <div className=" absolute bottom-0  py-6 px-2 bg-black/60 hover:bg-black/20 duration-300 ">
                <p className=" font-subheading text-white">
                  Intraday stock cash Aim recommendations are sent to the
                  customers through SMS or WhatsApp.
                </p>
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </>
  );
};

export default CashService;
