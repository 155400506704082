import React, { useState } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import FormikControl from "../../components/ReusableFormField/FormikControl";
import { Link } from "react-router-dom";
import { useChangePasswordMutation } from "../../services/profile";
import { getToken } from "../../services/localStorage";
import { ToastContainer, toast } from 'react-toastify';

const AdminChangePass = () => {
  const [changepass] =useChangePasswordMutation()
  const token = getToken('token');

  const [formKey, setFormKey] = useState(0);
  const [hide, setHide] = useState(true);

  const initialValues = {
    newPass: "",
    confirmPass: "",
  };
  const onSubmit = async (values, onSubmitProps) => {
    console.log("From Data", values);
  const res = await changepass({values,token})
  console.log(res.data,'24')
  toast(res.data.message)
  if(res.data.status=="succes"){
    onSubmitProps.setSubmitting(false);
    onSubmitProps.resetForm();
  }
    // console.log("Saved Data", JSON.parse(JSON.stringify(values)));
    
    setFormKey((prevKey) => prevKey + 1);
  };
  const validationSchema = Yup.object({
    newPass: Yup.string()
      .required("Password is required")
      .min(8, "Password must be at least 8 characters")
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@#$%^&!])[A-Za-z\d@#$%^&!]{8,}$/,
        "Password must contain at least one uppercase letter, one lowercase letter, one digit, and one special character."
      ),
    confirmPass: Yup.string()
      .required("Password is required")
      .min(8, "Password must be at least 8 characters")
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@#$%^&!])[A-Za-z\d@#$%^&!]{8,}$/,
        "Password must contain at least one uppercase letter, one lowercase letter, one digit, and one special character."
      ),
  });

  const handleVisible = () => {
    setHide(!hide);
  };

  return (
    <>
    <ToastContainer/>
      <div className="bg-white_background shadow-xl border  shadow-black  p-4 rounded-md md:w-7/12 sm:w-10/12 m-auto">
        <div className="flex text-center justify-between items-center border-b border-black/30 my-3  sm:flex-row p-2">
          <h3 className="text-center">Change Your Admin Password</h3>
        </div>

        {/* Form */}
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={validationSchema}
          validateOnMount
        >
          {(formik) => {
            console.log("formik", Formik);
            return (
              <Form>
                <div className="grid grid-cols-1 gap-6">
                  <FormikControl
                    control="password"
                    type={`${hide ? "password" : "text"}`}
                    label="New Password"
                    name="newPass"
                    valid={formik.errors.newPass && formik.touched.newPass}
                    visible={handleVisible}
                    placeholder="Enter Your New Password "
                    hide={hide}
                  />

                  <FormikControl
                    control="password"
                    type={`${hide ? "password" : "text"}`}
                    label="Confirm Password"
                    name="confirmPass"
                    valid={
                      formik.errors.confirmPass && formik.touched.confirmPass
                    }
                    visible={handleVisible}
                    placeholder="Confirm Password "
                    hide={hide}
                  />
                  <button
                    className="px-4 py-2 rounded bg-text_blue active:text-white disabled:bg-red-500 disabled:cursor-not-allowed"
                    disabled={!formik.isValid || formik.isSubmitting}
                    type="submit"
                  >
                    Submit
                  </button>
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    </>
  );
};

export default AdminChangePass;
