import React from "react";

const HomeAboutUs = () => {
  return (
    <>
      <div className="min-h-screen relative -z-40 sm:w-10/12 w-full m-auto my-4 ">
        <div className="bg-primary h-[40vh] rounded-3xl flex justify-center items-center uppercase font-subheading font-light text-white  flex-col">
          <h3 className="font-subheading text-light">Who We Are?</h3>
          <p>Let's Learn About Our Company</p>
          <p className="text-text_blue text-center">
            Unveiling Our Mission, Values, and Commitment to Your Success
          </p>
        </div>

        <div className="w-10/12 bg-black shadow-2xl shadow-secondary  rounded-3xl min-h-[60vh] absolute m-auto top-1/3 left-1/2 -translate-x-1/2 grid justify-items-center items-center p-5 text-center text-white">
          <p className="font-light md:text-xl">
            KVK Advisories, a trusted name in the financial services arena,
            provides you with the entire gamut of research analyst services
            under one roof. It is one of the few organizations providing
            research and information on the Indian capital markets, mainly based
            on Technical Analysis, and enjoys a strong reputation among
            investors, brokers, and researchers. Our team is highly skilled with
            experienced analysts. Our efforts aim to maximize profits in every
            trade for you.
          </p>
        </div>
      </div>
    </>
  );
};

export default HomeAboutUs;
