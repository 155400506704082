import React from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import FormikControl from "./ReusableFormField/FormikControl";
import { useAddKycMutation } from "../services/profile";
import { ToastContainer, toast } from "react-toastify";

const genderOptions = [
  { key: "Male", value: "male" },
  { key: "Women", value: "women" },
  { key: "Other", value: "other" },
];

const maritalOptions = [
  { key: "Married", value: "married" },
  { key: "Un-married", value: "unmarried" },
];

const incomeOptions = [
  { key: "Select Your Income", value: "" },
  { key: "0-1 Lakh", value: "0-1 lakh" },
  { key: "1-3 Lakh", value: "1-3 lakh" },
  { key: "3-10 Lakh", value: "3-10 lakh" },
  { key: "10-20 Lakh", value: "10-20 lakh" },
  { key: "20 Lakh +", value: "20 lakh +" },
];

const initialValues = {
  fullName: "",
  email: "",
  phoneNumber: "",
  gender: "",
  materialStatus: "",
  dateOfBirth: "",
  address: "",
  anAnnualIncome: "",
  occupation: "",
  investPrice: "",
};

const validationSchema = Yup.object({
  fullName: Yup.string().min(3).max(40).required("Required"),
  occupation: Yup.string().min(3).max(40).required("Required"),
  address: Yup.string().min(3).max(30).required("Required"),
  email: Yup.string().email("Invalie email formate").required("Required"),
  dateOfBirth: Yup.date()
    .required("Date of birth is required")
    .max(new Date(), "Date of birth cannot be in the future"),
  anAnnualIncome: Yup.string().required("Required"),
  gender: Yup.string().required("Select an option"),
  materialStatus: Yup.string().required("Select an option"),
  phoneNumber: Yup.string()
    .required("Mobile number is required")
    .matches(/^[6-9]\d{9}$/, "Invalid mobile number"),
  investPrice: Yup.number()
    .integer("Amount must be an integer")
    .positive("Amount must be a positive number")
    .required("Amount is required"),
});

const KYCForm = () => {
  const [addKyc] = useAddKycMutation();

  const onSubmit = async (values, onSubmitProps) => {
    const res = await addKyc(values);
    toast(res.data.message);

    // onSubmitProps.resetForm();
    if (res.data.message == "success") onSubmitProps.setSubmitting(false);
    {
      onSubmitProps.resetForm();
    }
  };
  return (
    <>
      <ToastContainer />
      <div className="bg-gradient-to-r from-primary via-secondary to-ternary grid grid-cols-1 gap-3 p-5 border-b border-slate-300/30">
        <h3 className="text-center text-white font-bold">KYC Form</h3>

        {/* Form */}
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={validationSchema}
          validateOnMount
        >
          {(formik) => {
            return (
              <Form className="grid gap-3 md:w-6/12 sm:w-8/12 w-11/12 m-auto bg-white shadow-2xl shadow-black p-6 rounded-3xl">
                {/* Name */}
                <FormikControl
                  control="input"
                  type="text"
                  label="Full Name"
                  name="fullName"
                  valid={formik.errors.fullName && formik.touched.fullName}
                  placeholder="Enter Your FullName"
                />
                {/* Email */}
                <FormikControl
                  control="input"
                  type="email"
                  label="Email"
                  name="email"
                  valid={formik.errors.email && formik.touched.email}
                  placeholder="Enter Your Email"
                />
                {/* Phone Number */}
                <FormikControl
                  control="input"
                  type="number"
                  label="Phone Number"
                  name="phoneNumber"
                  valid={
                    formik.errors.phoneNumber && formik.touched.phoneNumber
                  }
                  placeholder="Enter Your Number"
                />
                {/* Gender */}
                <FormikControl
                  control="radio"
                  label="Gender"
                  name="gender"
                  placeholder="Gender"
                  options={genderOptions}
                />
                {/* Marital Status */}
                <FormikControl
                  control="radio"
                  label="Marital status"
                  name="materialStatus"
                  placeholder="marital status"
                  options={maritalOptions}
                />
                {/* Normal Date */}
                <FormikControl
                  control="input"
                  type="date"
                  label="Enter Your Date"
                  name="dateOfBirth"
                  valid={formik.errors.normalDate && formik.touched.normalDate}
                  placeholder="Enter Your Date"
                />

                {/* address */}
                <FormikControl
                  control="input"
                  type="text"
                  label="City"
                  name="address"
                  valid={formik.errors.address && formik.touched.address}
                  placeholder="Address"
                />
                {/* Annual Income */}
                <FormikControl
                  control="select"
                  label=" Annual Income "
                  name="anAnnualIncome"
                  options={incomeOptions}
                  valid={
                    formik.errors.anAnnualIncome &&
                    formik.touched.anAnnualIncome
                  }
                />

                {/* investPrice */}
                <FormikControl
                  control="input"
                  type="number"
                  label="Minimum Investment (RS.)"
                  name="investPrice"
                  valid={
                    formik.errors.investPrice && formik.touched.investPrice
                  }
                  placeholder="Investment Amout in Rupees eg; 5000 RS"
                />

                <FormikControl
                  control="input"
                  type="text"
                  label="Occupation"
                  name="occupation"
                  valid={formik.errors.occupation && formik.touched.occupation}
                  placeholder="Enter Your Occupation"
                />
                <button
                  className="px-4 py-2 rounded bg-text_blue active:text-white disabled:bg-red-500 disabled:cursor-not-allowed"
                  disabled={!formik.isValid || formik.isSubmitting}
                  type="submit"
                >
                  Submit
                </button>
              </Form>
            );
          }}
        </Formik>
      </div>
    </>
  );
};

export default KYCForm;
