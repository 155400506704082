import React, { useState } from "react";
// import cash from "../Assets/cashe.jpg";
import call from "../assets/call option.jpg";
import put from "../assets/put option.jpg";

const OptionService = () => {
  const [content1, setContent1] = useState("");
  return (
    <>
      <div className="py-6 flex flex-col gap-12">
        <div className=" md:w-10/12 w-11/12 m-auto space-y-12">
          <div className=" flex flex-col gap-5 justify-center">
            <h3 className=" font-heading ">Option Service</h3>
            <p className=" font-body leading-7">
              In the context of the share market, an option refers to a
              financial instrument that gives the holder the right, but not the
              obligation, to buy or sell a specific quantity of a particular
              stock or other underlying asset at a predetermined price (known as
              the strike price) within a specified period of time.
            </p>
          </div>

          <div className="space-y-16 bg-black/70 py-8 text-white p-6">
            <div className=" grid grid-cols-1 md:grid-cols-2 gap-6">
              <div>
                <img src={call} className="w-full h-full rounded-md" />
              </div>
              <div className="flex flex-col gap-5 justify-center p-4">
                <h3 className="font-heading">Call Option</h3>
                <p className="font-body leading-7">
                  A call option gives the holder the right to buy the underlying
                  asset at the strike price within the specified time period. If
                  the price of the underlying asset increases above the strike
                  price, the call option becomes valuable, as the holder can
                  purchase the asset at a lower price and potentially profit
                  from the price difference.
                </p>
              </div>
            </div>

            <div className=" grid grid-cols-1 md:grid-cols-2 gap-6">
              <div className="flex flex-col gap-5 justify-center p-4 ">
                <h3 className="font-heading">Put option</h3>
                <p className="font-body leading-7">
                  A put option gives the holder the right to sell the underlying
                  asset at the strike price within the specified time period. If
                  the price of the underlying asset decreases below the strike
                  price, the put option becomes valuable, as the holder can sell
                  the asset at a higher price and potentially profit from the
                  price difference.
                </p>
              </div>

              <div className="order-first md:order-none ">
                <img src={put} className="w-full h-full rounded-md " />
              </div>
            </div>

            <p
              className="text-center leading-7 heading bg-admin-primary min-h-[40vh] py-4 px-6 rounded-md *
            flex justify-center items-center"
            >
              "Options are commonly used in the share market for various
              purposes, including speculation, hedging, and income generation.
              Traders and investors use options to take advantage of market
              movements, protect their portfolios from adverse price
              fluctuations, or generate additional income through option
              writing. It's important to note that options trading involves
              risks, and individuals should have a good understanding of how
              options work before engaging in such trading. It's recommended to
              consult with a financial advisor or seek proper education and
              knowledge about options before participating in options trading
              activities".
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default OptionService;
