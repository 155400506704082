import React from "react";
import { CgDanger } from "react-icons/cg";
import { ToastContainer, toast } from 'react-toastify';

const DeletePopUp = ({ visible, onClose,DeleteId }) => {
  
  const handleOnClose = async(e) => {
    if (e.target.id === "container") onClose();
  };
  const handleDelete =()=>{
    DeleteId()
     onClose()
  }

  if (!visible) return null;
  return (
    <>
    <ToastContainer />
      <div
        id="container"
        onClick={handleOnClose}
        className="fixed inset-0 bg-black/30 z-[1000]  backdrop-blur-sm flex justify-center items-center"
      >
        <div className="bg-white p-2 rounded flex flex-col gap-3">
          <div className="text-text_blue text-xl border-b border-slate-500/30 flex gap-2 items-center p-2 ">
            <CgDanger /> Delete Contact ?
          </div>
          <div className="flex flex-col gap-5 w-10/12 m-auto text-sm">
            <p>Are you sure you want to delete this contact ?</p>
            <div className="flex justify-between ">
              <button className="btn bg-gray-400 text-white" onClick={onClose}>
                Cancel
              </button>
              <button onClick={handleDelete} className="btn bg-red-500 hover:bg-red-900 text-white ">
                Delete
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DeletePopUp;
