import React, { useEffect, useState } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import FormikControl from "../../components/ReusableFormField/FormikControl";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { useResetPasswordMutation } from "../../services/profile";
import Logo from "../../assets/KVK Logo (1).svg";

const AdminForgetPassChange = () => {
  const [resetPassword, { isLoading }] = useResetPasswordMutation();
  const [formKey, setFormKey] = useState(0);
  const [hide, setHide] = useState(true);
  const navigate = useNavigate();
  const { state } = useLocation();

  const initialValues = {
    newPass: "",
    confirmPass: "",
    email: state?.email,
  };
  useEffect(() => {
    if (!state?.email) {
      navigate("/mailconfirm");
    }
  }, [state?.email]);

  const onSubmit = async (values, onSubmitProps) => {
    console.log("From Data", values);
    // console.log("Saved Data", JSON.parse(JSON.stringify(values)));
    const res = await resetPassword(values);
    if (res.data.status == "success") {
      onSubmitProps.setSubmitting(false);
      onSubmitProps.resetForm();
      navigate("/adminlogin");
      setFormKey((prevKey) => prevKey + 1);
    }
  };
  const validationSchema = Yup.object({
    newPass: Yup.string()
      .required("Password is required")
      .min(8, "Password must be at least 8 characters")
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@#$%^&!])[A-Za-z\d@#$%^&!]{8,}$/,
        "Password must contain at least one uppercase letter, one lowercase letter, one digit, and one special character."
      ),
    confirmPass: Yup.string()
      .required("Password is required")
      .min(8, "Password must be at least 8 characters")
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@#$%^&!])[A-Za-z\d@#$%^&!]{8,}$/,
        "Password must contain at least one uppercase letter, one lowercase letter, one digit, and one special character."
      ),
  });

  const handleVisible = () => {
    setHide(!hide);
  };

  return (
    <>
      <div className="bg-white_background shadow-xl border  shadow-black  p-4 rounded-md md:w-7/12 sm:w-10/12 m-auto">
        <div className="flex  justify-between items-center border-b border-black/30 my-3 flex-col sm:flex-row p-2">
          <span>
            <Link to="/">
              <img className="h-12 w-12 " src={Logo} alt="" />
            </Link>
          </span>
          <h3 className="text-2xl font-black text-transparent inline-block bg-clip-text bg-gradient-to-r from-text_blue to-green_main">
            KVK Advisories
          </h3>
        </div>

        {/* Form */}
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={validationSchema}
          validateOnMount
        >
          {(formik) => {
            console.log("formik", Formik);
            return (
              <Form>
                <div className="grid grid-cols-1 gap-6">
                  <FormikControl
                    control="password"
                    type={`${hide ? "password" : "text"}`}
                    label="New Password"
                    name="newPass"
                    valid={formik.errors.newPass && formik.touched.newPass}
                    visible={handleVisible}
                    placeholder="Enter Your New Password "
                    hide={hide}
                  />

                  <FormikControl
                    control="password"
                    type={`${hide ? "password" : "text"}`}
                    label="Confirm Password"
                    name="confirmPass"
                    valid={
                      formik.errors.confirmPass && formik.touched.confirmPass
                    }
                    visible={handleVisible}
                    placeholder="Confirm Password "
                    hide={hide}
                  />
                  <button
                    className="px-4 py-2 rounded bg-text_blue active:text-white disabled:bg-red-500 disabled:cursor-not-allowed"
                    disabled={!formik.isValid || formik.isSubmitting}
                    type="submit"
                  >
                    Submit
                  </button>
                </div>
              </Form>
            );
          }}
        </Formik>

        <div className="flex justify-between py-4 text-sm ">
          <span>Remember me</span>
          <Link to="mailconfirm">
            <span>Forget Password ?</span>
          </Link>
        </div>
      </div>
    </>
  );
};

export default AdminForgetPassChange;
