import React from "react";
import QRcodePage from "./QRcodePage";

import KickStart from "../assets/kick start.jpg";
import Seemless from "../assets/seamless transition.jpg";
import LifeTime from "../assets/lifetime access.jpg";
import Profit from "../assets/profit share model.jpg";
const ProcessMainPage = () => {
  return (
    <>
      <div
        className=" text-white py-10 bg-black
      space-y-20"
      >
        <div className="space-y-3 w-11/12 m-auto pt-6">
          <h3 className=" font-heading text-center font-medium">
            Empower Your Trading Journey with Our Proven Approach!
          </h3>
          <h3 className=" font-subheading text-center font-medium">
            Success in trading lies in the efficiency of your strategy.
          </h3>
        </div>

        {/*work container */}
        <div
          className="w-11/12 m-auto 
        bg-gradient-to-r from-primary via-secondary to-ternary rounded-2xl bg-opacity-70 "
        >
          <div className="space-y-8 py-6">
            <div className="flex flex-col justify-center items-center gap-6">
              <h3 className="text-center">Our Working Pattern</h3>
              <div className="h-1 bg-green-600 w-16 text-center"></div>
            </div>

            {/* work main */}
            <div className="space-y-16">
              <div className="grid grid-cols-1 md:grid-cols-2 min-h-[50vh] gap-8 w-11/12 m-auto">
                <div className="border border-white/10 rounded-md">
                  <img
                    src={KickStart}
                    className="rounded-md w-full h-80 object-cover"
                  />
                </div>
                <div className="flex flex-col gap-4 items-center justify-center">
                  <h3>
                    1. Kickstart With
                    <span className=" text-web_blue text-4xl"> Confidence</span>
                  </h3>
                  <p className="text-center">
                    Benefit from 1-2 free demo calls to trade with 100 to 200
                    quantity and experience our accuracy firsthand.
                  </p>
                </div>
              </div>

              <div className="grid grid-cols-1 md:grid-cols-2 min-h-[50vh] gap-8 w-11/12 m-auto">
                <div className="flex flex-col gap-4 items-center justify-center">
                  <h3 className="font-heading">
                    2. Seamless
                    <span className=" text-web_blue text-4xl font-subheading">
                      Transition
                    </span>
                  </h3>
                  <p className="text-center">
                    If you find our demo calls profitable, seamlessly continue
                    with us based on the accuracy levels observed during the
                    trial period.
                  </p>
                </div>

                <div className="border border-white/10 rounded-md order-first md:order-none">
                  <img
                    src={Seemless}
                    className="rounded-md w-full h-80 object-cover"
                  />
                </div>
              </div>

              <div className="grid grid-cols-1 md:grid-cols-2 min-h-[50vh] gap-8 w-11/12 m-auto">
                <div className="border border-white/10 rounded-md">
                  <img
                    src={LifeTime}
                    className="rounded-md w-full h-80 object-cover"
                  />
                </div>
                <div className="flex flex-col gap-4 items-center justify-center">
                  <h3 className="font-heading">
                    3. Lifetime{" "}
                    <span className=" text-web_blue text-4xl font-subheading">
                      Access
                    </span>
                  </h3>
                  <p className="text-center">
                    After the free trials, unlock lifetime service by paying a
                    one-time registration fee of Rs. 3999, ensuring
                    uninterrupted support and guidance.
                  </p>
                </div>
              </div>

              <div className="grid grid-cols-1 md:grid-cols-2 min-h-[50vh] gap-8 w-11/12 m-auto">
                <div className="flex flex-col gap-4 items-center justify-center">
                  <h3 className="font-heading">
                    4. Profit-Sharing{" "}
                    <span className=" text-web_blue text-4xl font-subheading">
                      Model
                    </span>
                  </h3>
                  <p className="text-center">
                    Embrace our profit-sharing model where once you earn a
                    profit of Rs. 10 thousand or more, share 30 percent of your
                    net profit with us, reducing your brokerage costs and
                    maximizing gains.
                  </p>
                </div>

                <div className="border border-white/10 rounded-md order-first md:order-none">
                  <img
                    src={Profit}
                    className="rounded-md w-full h-80 object-cover"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* At kyk advisiory */}
        <div className=" bg-primary w-11/12 m-auto p-8 min-h-[45vh] rounded-xl flex flex-col justify-center gap-4">
          <h3 className="text-green-800">At KVK Advisories </h3>
          <p className="leading-7 font-heading ">
            we prioritize transparency and trust in every step of your trading
            journey. Our proven approach ensures that you have the tools and
            support you need to navigate the markets successfully. With our free
            demo calls, you can experience the accuracy of our recommendations
            firsthand before making any commitments. Our one-time registration
            fee of Rs. 3999 grants you lifetime access to our services,
            guaranteeing uninterrupted support and guidance. Plus, our
            profit-sharing model incentivizes mutual success – when you win, we
            win. Join us today and let's chart a path to profitability together.
          </p>
        </div>

        {/* qr code */}

        <div className="space-y-16">
          <h3 className="font-medium fint-heading text-center">
            "Secure, Reliable, and Trustworthy Payments Made Easy! "
          </h3>

          {/* <div
            className="grid grid-cols-1 md:grid-cols-3 border border-white/20 w-11/12 m-auto min-h-[50vh]
          gap-6 md:gap-3"
          >
            <div>
              <img
                src="https://download.logo.wine/logo/PhonePe/PhonePe-Logo.wine.png"
                className="h-[50vh]"
              />
            </div>

            <div className="col-span-1 md:col-span-2 flex flex-col justify-center gap-3">
              <h3>QR Code</h3>
              <p>
                A QR code is a type of barcode that can be read easily by a
                digital device and which stores information as a series of
                pixels in a square-shaped grid.
              </p>
              <p>
                QR Code can be used in many ways like to show product details,
                track product delivery, to show menu to customers in
                restaurants, for accessing social media platforms, to get access
                to any webinar or any other site or link, and to transfer money
                (Paytm).
              </p>
            </div>
          </div>

          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 min-h-[50vh] w-11/12 m-auto gap-4">
            <div>
              <img
                src="https://wallpapers.com/images/hd/trading-wallpaper-ynfqhj74ml8p96ca.jpg"
                className="w-full h-full opacity-40 hover:opacity-95 duration-300
                 hover:-translate-y-4 rounded-md shadow-lg"
              />
            </div>

            <div>
              <img
                src="https://wallpapers.com/images/hd/trading-wallpaper-ynfqhj74ml8p96ca.jpg"
                className="w-full h-full opacity-40 hover:opacity-95 duration-300
               hover:-translate-y-4 rounded-md shadow-lg"
              />
            </div>

            <div>
              <img
                src="https://wallpapers.com/images/hd/trading-wallpaper-ynfqhj74ml8p96ca.jpg"
                className="w-full h-full opacity-40 hover:opacity-95 duration-300 
              hover:-translate-y-4 rounded-md shadow-lg"
              />
            </div>

            <div>
              <img
                src="https://wallpapers.com/images/hd/trading-wallpaper-ynfqhj74ml8p96ca.jpg"
                className="w-full h-full opacity-40 hover:opacity-95 duration-300
               hover:-translate-y-4 rounded-md shadow-lg"
              />
            </div>
          </div> */}

          <QRcodePage />

          <div className=" bg-primary w-11/12 m-auto p-8 min-h-[60vh] rounded-xl flex flex-col justify-center gap-4">
            <h3 className="text-green-800">At KVK Advisories </h3>
            <p className="leading-7 font-heading ">
              we prioritize your security and satisfaction above all else. Our
              QR code payment system ensures that your transactions are
              conducted seamlessly and securely every time. With our commitment
              to transparency and reliability, you can trust that your financial
              transactions are in good hands. Why wait to experience the
              convenience of modern payments? Join now and enjoy the peace of
              mind that comes with our trusted services. If you have any doubts
              or questions, our dedicated advisors are here to assist you. Feel
              free to reach out before completing your payment to have all your
              concerns addressed. Your satisfaction is our priority, and we're
              here to ensure that your experience with us is nothing short of
              exceptional.
            </p>
            <p className="leading-7 font-heading ">
              Why wait? Join now and experience the ease and security of our
              payment solutions. If you have any doubts, our advisors are here
              to help you every step of the way.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProcessMainPage;
