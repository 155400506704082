import React from "react";
import { Link } from "react-router-dom";

const AdminGeneralSettings = () => {
  return (
    <>
      <div className="bg-[#0F172A] min-h-full p-6 text-white  w-full">
        <div className="">
          <div className=" grid-cols-fit-md grid gap-7">
            <div className=" bg-gray-700/30 grid rounded p-2 gap-3 hover:scale-105 duration-200  ">
              <h3 className="text-bold text-xl">Dashboard </h3>
              <p>Change Your Admin Dashboard Password </p>
              <div className="grid justify-items-end ">
                <Link to="/admindashboard/changepass">
                  <button className="px-4 py-2 border shadow rounded-md ">
                    Change Password
                  </button>
                </Link>
              </div>
            </div>
            <div className=" bg-yellow-400 grid rounded p-2 gap-3 hover:scale-105 duration-200 ">
              <h3 className="text-bold text-xl">Updates</h3>
              <p>Change Your Gmail, Phone, UserName</p>
              <div className="grid justify-items-end ">
                <Link to="/admindashboard/changegmail">
                  <button className="px-4 py-2 border shadow rounded-md ">
                    Change
                  </button>
                </Link>
              </div>
            </div>

            <div className=" bg-green-600 grid rounded p-2 gap-3 hover:scale-105 duration-200 ">
              <h3 className="text-bold">QRCode</h3>
              <p>Update Your QRCode</p>
              <div className="grid justify-items-end ">
                <Link to="/admindashboard/updateqrcode">
                  <button className="px-4 py-2 border shadow rounded-md ">
                    Update QRCode
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AdminGeneralSettings;
