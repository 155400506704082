import React, { useState } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import FormikControl from "../../components/ReusableFormField/FormikControl";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useAdminLoginMutation } from "../../services/profile";
import { storeToken } from "../../services/localStorage";
import { ToastContainer, toast } from "react-toastify";

const AdminLoginHomePage = () => {
  const [login] = useAdminLoginMutation();
  const navigate = useNavigate();
  const [formKey, setFormKey] = useState(0);
  const [hide, setHide] = useState(true);

  const initialValues = {
    userName: "",
    pass: "",
  };
  const onSubmit = async (values, onSubmitProps) => {
    console.log("From Data", values);
    const res = await login(values);

    storeToken(res.data.token);
    if (res.data.status == "success") {
      navigate("/admindashboard");
      onSubmitProps.setSubmitting(false);
      onSubmitProps.resetForm();
      setFormKey((prevKey) => prevKey + 1);
    }
    toast(res.data.message);

    // console.log("Saved Data", JSON.parse(JSON.stringify(values)));
  };
  const validationSchema = Yup.object({
    userName: Yup.string().min(3).max(30).required("Required"),
    pass: Yup.string()
      .required("Password is required")
      .min(8, "Password must be at least 8 characters")
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@#$%^&!])[A-Za-z\d@#$%^&!]{8,}$/,
        "Password must contain at least one uppercase letter, one lowercase letter, one digit, and one special character."
      ),
  });

  const handleVisible = () => {
    setHide(!hide);
  };

  return (
    <>
      <ToastContainer />
      <div className="bg-white_background shadow-xl border  shadow-black  p-4 rounded-md md:w-7/12 sm:w-10/12 m-auto">
        <div className="flex  justify-between items-center border-b border-black/30 my-3 flex-col sm:flex-row p-2">
          <span>
            <Link to="/">
              <img className="h-12 w-12 " src="KVK Logo (1).svg" alt="" />
            </Link>
          </span>
          <h3 className="text-2xl font-black text-transparent inline-block bg-clip-text bg-gradient-to-r from-text_blue to-green_main">
            KVK Advisories
          </h3>
        </div>

        {/* Form */}
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={validationSchema}
          validateOnMount
        >
          {(formik) => {
            console.log("formik", Formik);
            return (
              <Form>
                <div className="grid grid-cols-1 gap-6">
                  <FormikControl
                    control="input"
                    type="text"
                    label="User Name"
                    name="userName"
                    valid={formik.errors.userName && formik.touched.userName}
                    placeholder="Enter Your User Name"
                  />
                  <FormikControl
                    control="password"
                    type={`${hide ? "password" : "text"}`}
                    label="Password"
                    name="pass"
                    valid={formik.errors.pass && formik.touched.pass}
                    visible={handleVisible}
                    placeholder="Enter Password "
                    hide={hide}
                  />
                  <button
                    className="px-4 py-2 rounded bg-text_blue active:text-white disabled:bg-red-500 disabled:cursor-not-allowed"
                    disabled={!formik.isValid || formik.isSubmitting}
                    type="submit"
                  >
                    Submit
                  </button>
                </div>
              </Form>
            );
          }}
        </Formik>

        <div className="flex justify-between py-4 text-sm ">
          <span>Remember me</span>
          <Link to="mailconfirm">
            <span>Forget Password ?</span>
          </Link>
        </div>
      </div>
    </>
  );
};

export default AdminLoginHomePage;
