import React from "react";
import img from "../assets/pexels-rdne-stock-project-8370341.jpg";
import { BsArrowRight } from "react-icons/bs";
import { GiCalendarHalfYear } from "react-icons/gi";
import { Link } from "react-router-dom";

const HeroSection = () => {
  return (
    <>
      <div className="min-h-[calc(100vh-64px)] bg-gradient-to-r from-primary via-secondary to-ternary md:p-12 p-2 md:rounded-br-full   ">
        <div className="grid md:grid-cols-2 grid-cols-1 gap-4 h-full  lg:w-11/12 m-auto">
          <div className="flex w-full h-full justify-center md:items-start  flex-col md:gap-10 gap-4 md:p-5 items-center ">
            <div className="flex flex-col items-center justify-center md:items-start">
              <h1 className="text-white md:text-[64px] text-[35px]">
                Invest Smarter
              </h1>
              <h1 className=" text-text_blue ">Not Harder. ✌ </h1>
            </div>

            <p className="text-white font-body text-xl text-center md:text-left">
              "Our stock advisory firm offers personalized strategies tailored
              to your financial goals and risk tolerance."
            </p>

            <Link to="/contact" className="relative group text-sm">
              <div className="absolute -inset-0.5 group-hover:-inset-1 bg-gradient-to-r from-text_blue to-green_main opacity-80 group-hover:opacity-100 rounded-md blur group-hover:duration-200 duration-1000 animate-tile"></div>
              <button className=" relative px-7 py-4 bg-black rounded-lg leading-none flex items-center  text-white divide-x">
                <span className="pr-4 flex items-center gap-4 ">
                  <span>
                    <GiCalendarHalfYear className="text-text_blue text-xl" />
                  </span>
                  Contact Us
                </span>
                <span className="pl-4 flex items-center gap-4 text-green_main group-hover:text-purple-400">
                  Get Started
                  <BsArrowRight />
                </span>
              </button>
            </Link>
          </div>
          <div className="grid justify-items-center items-center  ">
            <img
              className="h-96 shadow-2xl rounded-3xl shadow-black"
              src={img}
              alt=""
            />
            {/* <img
              className="h-96 shadow-2xl"
              src="https://w7.pngwing.com/pngs/235/600/png-transparent-stock-market-design-elements-flat-arrows-line-chart-sketch.png"
              alt=""
            /> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default HeroSection;
