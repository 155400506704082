import React, { useState, useEffect } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import FormikControl from "../../components/ReusableFormField/FormikControl";
import { Link } from "react-router-dom";
import { useNavigate, useLocation } from "react-router-dom";
import { useVerifyOtpMutation } from "../../services/profile";
import Logo from "../../assets/KVK Logo (1).svg";

const AdminOTPConfirmPage = () => {
  const [verify, { isLoading }] = useVerifyOtpMutation();
  const navigate = useNavigate();
  const [formKey, setFormKey] = useState(0);
  const [hide, setHide] = useState(true);
  const { state } = useLocation();
  const initialValues = {
    otpConfirm: "",
    email: state?.email,
  };
  useEffect(() => {
    if (!state?.email) {
      navigate("/mailconfirm");
    }
  }, [state?.email]);

  const onSubmit = async (values, onSubmitProps) => {
    console.log("From Data", values);
    const res = await verify(values);
    console.log(res.data, "23");
    if (res.data.status == "success") {
      navigate("/adminlogin/forgetpasschange", { state: { email: values.email } });
      onSubmitProps.setSubmitting(false);
      onSubmitProps.resetForm();
    }
  };
  const validationSchema = Yup.object({
    otpConfirm: Yup.string().min(3).max(30).required("Required"),
  });

  const handleVisible = () => {
    setHide(!hide);
  };

  const changepass = () => {
    navigate("/adminlogin/forgetpasschange");
  };

  return (
    <>
      <div className="bg-white_background shadow-xl border  shadow-black  p-4 rounded-md md:w-7/12 sm:w-10/12 m-auto">
        <div className="flex  justify-between items-center border-b border-black/30 my-3 flex-col sm:flex-row p-2">
          <span>
            <Link to="/">
              <img className="h-12 w-12 " src={Logo} alt="" />
            </Link>
          </span>
          <h3 className="text-2xl font-black text-transparent inline-block bg-clip-text bg-gradient-to-r from-text_blue to-green_main">
            KVK Advisories
          </h3>
        </div>

        {/* Form */}
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          // validationSchema={validationSchema}
          validateOnMount
        >
          {(formik) => {
            console.log("formik", Formik);
            return (
              <Form>
                <div className="grid grid-cols-1 gap-6">
                  <FormikControl
                    control="input"
                    type="number"
                    label="Enter OTP"
                    name="otp"
                    valid={formik.errors.otp && formik.touched.otp}
                    placeholder="Enter Your OPT"
                  />

                  <button
                    className="px-4 py-2 rounded bg-text_blue active:text-white disabled:bg-red-500 disabled:cursor-not-allowed"
                    disabled={!formik.isValid || formik.isSubmitting}
                    type="submit"
                  >
                    Confirm OTP
                  </button>
                </div>
              </Form>
            );
          }}
        </Formik>

        <button onClick={changepass}>Go to change Password</button>
        <div className="text-center py-2 gap-3 flex flex-col">
          <h2 className="text-red-500 text-sm">NOTE:- DO NOT SHARE YOUR OTP</h2>
        </div>
      </div>
    </>
  );
};

export default AdminOTPConfirmPage;
