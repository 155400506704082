import React from "react";
import { Link } from "react-router-dom";

const DecodingSuccess = () => {
  return (
    <>
      <div className="bg-[#0F172A] min-h-screen justify-center p-6 text-white flex flex-col gap-5 py-10 my-20 w-full">
        <h3 className="text-center">Decoding Success</h3>
        <h2 className="text-center">
          Understanding How Your Stock Advisor Helps You Achieve Your Financial
          Goals!
        </h2>
        <div className="">
          <div className=" grid-cols-fit-md grid gap-7">
            <div className=" bg-yellow-500 grid rounded p-2 gap-3 hover:scale-105 duration-200  ">
              <h3 className="text-bold text-lg">Be Clear About Your Goals </h3>
              <p className="text-white/70">
                Clearly communicate your financial goals and risk tolerance to
                the advisor.
              </p>
            </div>
            <div className=" bg-green-500 grid rounded p-2 gap-3 hover:scale-105 duration-200  ">
              <h3 className="text-bold text-lg">Ask Questions </h3>
              <p className="text-white/70">
                Don't hesitate to ask questions about investment strategies,
                fees, and performance.
              </p>
            </div>
            <div className=" bg-pink-500 grid rounded p-2 gap-3 hover:scale-105 duration-200  ">
              <h3 className="text-bold text-lg">Stay Informed</h3>
              <p className="text-white/70">
                Stay up-to-date on market trends and news to have informed
                discussions with your advisor.
              </p>
            </div>
            <div className=" bg-orange-700 grid rounded p-2 gap-3 hover:scale-105 duration-200  ">
              <h3 className="text-bold text-lg">Trust Your Gut</h3>
              <p className="text-white/70">
                If something doesn't feel right, speak up and discuss your
                concerns with your advisor.
              </p>
            </div>
            <div className=" bg-purple-600 grid rounded p-2 gap-3 hover:scale-105 duration-200  ">
              <h3 className="text-bold text-lg">
                Regularly Review Your Portfolio{" "}
              </h3>
              <p className="text-white/70">
                Schedule regular check-ins with your advisor to review your
                portfolio and make adjustments as needed.
              </p>
            </div>
            <div className=" bg-blue-500 grid rounded p-2 gap-3 hover:scale-105 duration-200  ">
              <h3 className="text-bold text-lg">Stay Patient </h3>
              <p className="text-white/70">
                Investing takes time, so trust in the process and avoid making
                impulsive decisions based on short-term market fluctuations.
              </p>
            </div>
            <div className=" bg-red-500 grid rounded p-2 gap-3 hover:scale-105 duration-200  ">
              <h3 className="text-bold text-lg"> Be Open to Feedback</h3>
              <p className="text-white/70">
                Listen to your advisor's recommendations and be willing to
                consider alternative perspectives.
              </p>
            </div>
            <div className=" bg-green-700 grid rounded p-2 gap-3 hover:scale-105 duration-200  ">
              <h3 className="text-bold text-lg">
                {" "}
                Keep Long-Term Goals in Mind
              </h3>
              <p className="text-white/70">
                Focus on your long-term financial objectives and avoid being
                swayed by short-term market fluctuations.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DecodingSuccess;
