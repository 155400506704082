import React, { useState, useEffect } from "react";
import DataTable, { createTheme } from "react-data-table-component";
import { MdDelete } from "react-icons/md";
import DeletePopUp from "../../components/DeletePopUp";
import { useGetAllKycQuery,useDeleteKycByIdMutation ,useActiveKycByIdMutation} from "../../services/profile";
import { getToken } from "../../services/localStorage";
import { ToastContainer, toast } from 'react-toastify';
import Loader from "../../components/Loader";

//Custom Theme eg:  theme="solarized"
createTheme(
  "solarized",
  {
    text: {
      primary: "#268bd2",
      secondary: "#2aa198",
    },
    background: {
      default: "#002b36",
    },
    context: {
      background: "#cb4b16",
      text: "#FFFFFF",
    },
    divider: {
      default: "#073642",
    },
    action: {
      button: "rgba(0,0,0,.54)",
      hover: "rgba(0,0,0,.08)",
      disabled: "rgba(0,0,0,.12)",
    },
  },
  "dark"
);

const KYCDataPage = () => {
  const token = getToken('token');
  const {data,isLoading} = useGetAllKycQuery(token)
 
  const [DeleteKyc] = useDeleteKycByIdMutation(token)
  const [ActivateKyc] = useActiveKycByIdMutation(token)
  const [showMyModal, setShowMyModal] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [_id, setId] = useState();

  const handleDelete = async(_id)=>{
    setShowMyModal(true)
    setId(_id)
  }
  const DeleteId = ()=>{
   const res=  DeleteKyc({_id,token})
   toast("res.data.message")
  }
  const handleOnClose = (_id) => {
    setShowMyModal(false)
  };

  const columns = [
    {
      name: "Full Name",
      selector: (row) => (
        <div className="text-admin-primary">{row.fullName}</div>
      ),
      sortable: true,
      style: {
        color: "yellow",
        fontSize: "14px",
        fontWeight: 400,
      },
    },

    {
      name: "Phone Number",
      selector: (row) => row.phoneNumber,
    },

    {
      name: "Gender",
      selector: (row) => row.gender,
      conditionalCellStyles: [
        {
          when: (row) => row.gender == "male",
          style: {
            backgroundColor: "blue",
            color: "white",
            "&:hover": {
              cursor: "not-allowed",
            },
          },
        },
        {
          when: (row) => row.gender == "female",
          style: {
            backgroundColor: "pink",
            color: "black",
            "&:hover": {
              cursor: "not-allowed",
            },
          },
        },
      ],
    },
    {
      name: "Date of Birth",
      selector: (row) => row.dateOfBirth,
      sortable: true,
    },

    {
      name: "Annual Income ",
      selector: (row) => row.anAnnualIncome,
    },
    {
      name: "Occupation ",
      selector: (row) => row.occupation,
    },
    {
      name: "investPrice ",
      selector: (row) => row.investPrice,
    },
    {
      name: "Action",
      cell: (row) => (
        <button
          onClick={() => handleDelete(row?._id)}
          className="btn border bg-red-300 text-black flex items-center justify-center gap-2 hover:scale-105 duration-300 text-sm hover:bg-red-600"
        >
          
          Delete 
          <MdDelete className="text-red-950 " />
        </button>
      ),
      button: true,
    },
    {
      name: "Action",
      cell: ({_id}) => (
        <button
          onClick={() => ActivateKyc({_id,token})}
          className="btn border bg-red-300 text-black flex items-center justify-center gap-2 hover:scale-105 duration-300 text-sm hover:bg-red-600"
        >
          
          Activate 
          {/* <MdDelete className="text-green-950 " /> */}
        </button>
      ),
      button: true,
    },
  ];

  // const data = [
  //   {
  //     id: 1,
  //     fullName: "Roshan Rana",
  //     email: "ranaroshan763@gmail.com",
  //     phoneNumber: "7974439538",
  //     gender: "male",
  //     materialStatus: "Un-married",
  //     dateOfBirth: "04/09/1995",
  //     panNumber: "CAAPR1412E",
  //     aadharNumber: "123454324567",
  //     address: "MIG 171/3d Saket nagar",
  //     anAnnualIncome: "3-5 Lakh",
  //     occupation: "Web Developer",
  //   },
  //   {
  //     id: 2,
  //     fullName: "Kiran",
  //     email: "Kiran763@gmail.com",
  //     phoneNumber: "7974439538",
  //     gender: "female",
  //     materialStatus: "Un-married",
  //     dateOfBirth: "04/09/1995",
  //     panNumber: "CAAPR1412E",
  //     aadharNumber: "123454324567",
  //     address: "MIG 171/3d Saket nagar",
  //     anAnnualIncome: "3-5 Lakh",
  //     occupation: "Web Developer",
  //   },
  //   {
  //     id: 3,
  //     fullName: "Roshan Rana",
  //     email: "ranaroshan763@gmail.com",
  //     phoneNumber: "7974439538",
  //     gender: "male",
  //     materialStatus: "Un-married",
  //     dateOfBirth: "04/09/1995",
  //     panNumber: "CAAPR1412E",
  //     aadharNumber: "123454324567",
  //     address: "MIG 171/3d Saket nagar",
  //     anAnnualIncome: "3-5 Lakh",
  //     occupation: "Web Developer",
  //   },
  //   {
  //     id: 3,
  //     fullName: "Roshan Rana",
  //     email: "ranaroshan763@gmail.com",
  //     phoneNumber: "7974439538",
  //     gender: "male",
  //     materialStatus: "Un-married",
  //     dateOfBirth: "04/09/1995",
  //     panNumber: "CAAPR1412E",
  //     aadharNumber: "123454324567",
  //     address: "MIG 171/3d Saket nagar",
  //     anAnnualIncome: "3-5 Lakh",
  //     occupation: "Web Developer",
  //   },
  //   {
  //     id: 3,
  //     fullName: "Roshan Rana",
  //     email: "ranaroshan763@gmail.com",
  //     phoneNumber: "7974439538",
  //     gender: "male",
  //     materialStatus: "Un-married",
  //     dateOfBirth: "04/09/1995",
  //     panNumber: "CAAPR1412E",
  //     aadharNumber: "123454324567",
  //     address: "MIG 171/3d Saket nagar",
  //     anAnnualIncome: "3-5 Lakh",
  //     occupation: "Web Developer",
  //   },
  //   {
  //     id: 3,
  //     fullName: "Roshan Rana",
  //     email: "ranaroshan763@gmail.com",
  //     phoneNumber: "7974439538",
  //     gender: "male",
  //     materialStatus: "Un-married",
  //     dateOfBirth: "04/09/1995",
  //     panNumber: "CAAPR1412E",
  //     aadharNumber: "123454324567",
  //     address: "MIG 171/3d Saket nagar",
  //     anAnnualIncome: "3-5 Lakh",
  //     occupation: "Web Developer",
  //   },
  //   {
  //     id: 3,
  //     fullName: "Roshan Rana",
  //     email: "ranaroshan763@gmail.com",
  //     phoneNumber: "7974439538",
  //     gender: "male",
  //     materialStatus: "Un-married",
  //     dateOfBirth: "04/09/1995",
  //     panNumber: "CAAPR1412E",
  //     aadharNumber: "123454324567",
  //     address: "MIG 171/3d Saket nagar",
  //     anAnnualIncome: "3-5 Lakh",
  //     occupation: "Web Developer",
  //   },
  //   {
  //     id: 3,
  //     fullName: "Roshan Rana",
  //     email: "ranaroshan763@gmail.com",
  //     phoneNumber: "7974439538",
  //     gender: "male",
  //     materialStatus: "Un-married",
  //     dateOfBirth: "04/09/1995",
  //     panNumber: "CAAPR1412E",
  //     aadharNumber: "123454324567",
  //     address: "MIG 171/3d Saket nagar",
  //     anAnnualIncome: "3-5 Lakh",
  //     occupation: "Web Developer",
  //   },
  //   {
  //     id: 3,
  //     fullName: "Roshan Rana",
  //     email: "ranaroshan763@gmail.com",
  //     phoneNumber: "7974439538",
  //     gender: "male",
  //     materialStatus: "Un-married",
  //     dateOfBirth: "04/09/1995",
  //     panNumber: "CAAPR1412E",
  //     aadharNumber: "123454324567",
  //     address: "MIG 171/3d Saket nagar",
  //     anAnnualIncome: "3-5 Lakh",
  //     occupation: "Web Developer",
  //   },
  //   {
  //     id: 3,
  //     fullName: "Roshan Rana",
  //     email: "ranaroshan763@gmail.com",
  //     phoneNumber: "7974439538",
  //     gender: "male",
  //     materialStatus: "Un-married",
  //     dateOfBirth: "04/09/1995",
  //     panNumber: "CAAPR1412E",
  //     aadharNumber: "123454324567",
  //     address: "MIG 171/3d Saket nagar",
  //     anAnnualIncome: "3-5 Lakh",
  //     occupation: "Web Developer",
  //   },
  // ];

  // Filter
  // useEffect(() => {
  //   // const result = data?.data.filter((value) => {
  //   const result = data?.filter((value) => {
  //     return (
  //       value.fullName.toLowerCase().includes(name.toLowerCase()) &&
  //       value.email.toLowerCase().includes(email.toLowerCase())
  //     );
  //   });
  //   setFilteredData(result);
  // }, [name, email]);

  const filteredData = data?.filter(
    (item) =>
      item.fullName.toLowerCase().includes(name.toLowerCase()) &&
      item.email.toLowerCase().includes(email.toLowerCase())
  );

  // Export Data
  // Blatant "inspiration" from https://codepen.io/Jacqueline34/pen/pyVoWr
  function convertArrayOfObjectsToCSV(array) {
    let result;

    const columnDelimiter = ",";
    const lineDelimiter = "\n";
    // const keys = Object.keys(data.data[0]);
    const keys = Object.keys(data && data[0]);

    result = "";
    result += keys.join(columnDelimiter);
    result += lineDelimiter;

    array.forEach((item) => {
      let ctr = 0;
      keys.forEach((key) => {
        if (ctr > 0) result += columnDelimiter;

        result += item[key];
        // eslint-disable-next-line no-plusplus
        ctr++;
      });
      result += lineDelimiter;
    });

    return result;
  }

  // Blatant "inspiration" from https://codepen.io/Jacqueline34/pen/pyVoWr
  function downloadCSV(array) {
    console.log(array);
    const link = document.createElement("a");
    let csv = convertArrayOfObjectsToCSV(array);
    if (csv == null) return;

    const filename = "export.csv";
    if (!csv.match(/^data:text\/csv/i)) {
      csv = `data:text/csv;charset=utf-8,${csv}`;
    }

    link.setAttribute("href", encodeURI(csv));
    link.setAttribute("download", filename);
    link.click();
  }

  // eslint-disable-next-line react/prop-types
  const Export = ({ onExport }) => (
    <button
      className="btn bg-admin-primary text-white text-sm"
      onClick={(e) => onExport(e.target.value)}
    >
      Export
    </button>
  );

  const actionsMemo = React.useMemo(
    // () => <Export onExport={() => downloadCSV(data.data)} />,
    () => <Export onExport={() => downloadCSV(data)} />,
    []
  );
  // Export Data Ends

  // Expandable Row Component
  const ExpandedComponent = ({ data }) => (
    <>
      {/* <pre>{JSON.stringify(data, null, 2)}</pre> */}
      <div className="grid p-2 gap-1 grid-cols-1 md:grid-cols-3 text-sm bg-admin-primary rounded-2xl m-auto duration-300 w-11/12 text-white ">
        <div className="flex flex-col gap-3 bg-black/80 p-2 rounded-md">
          <div className="flex gap-3 items-center">
            Name :<span className="text-lg">{data.fullName}</span>
          </div>
          <div className="flex gap-3 items-center">
            Email :<span className="text-lg">{data.email}</span>
          </div>
          <div className="flex gap-3 items-center">
            Mobile Number :<span className="text-lg">{data.phoneNumber}</span>
          </div>
        </div>

        <div className="flex flex-col gap-3 bg-black/80 p-2 rounded-md">
          <div className="flex gap-3 items-center">
            Age :<span className="text-lg">{data.dateOfBirth}</span>
          </div>
          <div className="flex gap-3 items-center">
            Gender :<span className="text-lg">{data.gender}</span>
          </div>
          <div className="flex gap-3 items-center md:col-span-2">
            Address:<span className="text-lg">{data.address}</span>
          </div>
        </div>
        <div className="flex flex-col gap-3 bg-black/80 p-2 rounded-md">
          <div className="flex gap-3 items-center">
            Anuual Income :
            <span className="text-lg">{data.anAnnualIncome}</span>
          </div>
          <div className="flex gap-3 items-center">
            Occupation :<span className="text-lg">{data.occupation}</span>
          </div>
          <div className="flex gap-3 items-center">
          investPrice :<span className="text-lg">{data.investPrice}</span>
          </div>
        </div>
      </div>
    </>
  );

  return (
    <>
    <ToastContainer />
    {isLoading?<Loader size = "24" color = "blue" content = "kvk"/>:<div>
        <div className="  h-full text-text_blue p-4 w-full  ">
          <div className="grid ">
            <DataTable
              columns={columns}
              data={filteredData}
              pagination
              title="KYC Form Data"
              fixedHeader
              fixedHeaderScrollHeight="53vh"
              // dense
              highlightOnHover // When we Hover we can highlight the rows
              subHeader
              subHeaderComponent={
                <div className="grid grid-cols-2 gap-2">
                  <input
                    className="rounded p-1 focus:scale-x-105 duration-300"
                    type="text"
                    placeholder="search..."
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                  <input
                    className="rounded p-1 focus:scale-x-105 duration-300"
                    type="text"
                    placeholder="Deignation..."
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
              }
              subHeaderAlign="right"
              expandableRows
              expandableRowsComponent={ExpandedComponent}
              // customStyles={customStyles} // custom style
              theme="solarized" // Custom Theme
              actions={actionsMemo} // it is for CSV comma seperated value
            />
            {/* {console.log(_id)} */}
          </div>
        </div>
      </div>}
      

      <DeletePopUp onClose={handleOnClose} visible={showMyModal} DeleteId={DeleteId} />
    </>
  );
};

export default KYCDataPage;
