import React from "react";
import KYCForm from "../components/KYCForm";
import ProcessMainPage from "../components/ProcessMainPage";

const Price = () => {
  return (
    <>
      <div>
        <ProcessMainPage />
        <KYCForm />
      </div>
    </>
  );
};

export default Price;
