import React from "react";
import 'react-toastify/dist/ReactToastify.css';
import { Routes, Route } from "react-router-dom";
import MainPage from "./pages/MainPage";
import HomePage from "./pages/HomePage";
import ScrollTop from "./components/ScrollTop";
import AdminMainPage from "./AdminPanel/Adminpages/AdminMainPage";
import AdminHomePage from "./AdminPanel/Adminpages/AdminHomePage";
import AdminProfilePage from "./AdminPanel/Adminpages/AdminProfilePage";
import PageNotFound from "./pages/PageNotFound";
import AdminLoginPage from "./AdminPanel/Adminpages/AdminLoginPage";
import About from "./pages/About";
import Service from "./pages/Service";
import Price from "./pages/Price";
import Contact from "./pages/Contact";
import KYCDataPage from "./AdminPanel/Adminpages/KYCDataPage";
import ContactDataPage from "./AdminPanel/Adminpages/ContactDataPage";
import AdminLoginHomePage from "./AdminPanel/Adminpages/AdminLoginHomePage";
import AdminMailConfirm from "./AdminPanel/Adminpages/AdminMailConfirm";
import AdminOTPConfirmPage from "./AdminPanel/Adminpages/AdminOTPConfirmPage";
import AdminForgetPassChange from "./AdminPanel/Adminpages/AdminForgetPassChange";
import AdminQRCodeUpdate from "./AdminPanel/Adminpages/AdminQRCodeUpdate";
import AdminGeneralSettings from "./AdminPanel/Adminpages/AdminGeneralSettings";
import AdminChangePass from "./AdminPanel/Adminpages/AdminChangePass";
import AdminChangeGmail from "./AdminPanel/Adminpages/AdminChangeGmail";
import AdminChangePhone from "./AdminPanel/Adminpages/AdminChangePhone";
import AdminActiveUsers from "./AdminPanel/Adminpages/AdminActiveUsers";

const App = () => {
  return (
    <>
      <ScrollTop />
      <Routes>
        {/* UserSection */}
        <Route path="/" element={<MainPage />}>
          <Route index element={<HomePage />} />
          <Route path="about" element={<About />} />
          <Route path="service" element={<Service />} />
          <Route path="process" element={<Price />} />
          <Route path="contact" element={<Contact />} />
        </Route>

        {/* Admin Dashboard */}
        <Route path="adminlogin" element={<AdminLoginPage />}>
          <Route index element={<AdminLoginHomePage />} />
          <Route path="mailconfirm" element={<AdminMailConfirm />} />
          <Route path="otpconfirm" element={<AdminOTPConfirmPage />} />
          <Route path="forgetpasschange" element={<AdminForgetPassChange />} />
        </Route>
        <Route path="admindashboard" element={<AdminMainPage />}>
          <Route index element={<AdminHomePage />} />
          <Route path="profile" element={<AdminProfilePage />} />
          <Route path="kycdata" element={<KYCDataPage />} />
          <Route path="contactdata" element={<ContactDataPage />} />
          <Route path="activeuser" element={<AdminActiveUsers />} />
          <Route path="updateqrcode" element={<AdminQRCodeUpdate />} />
          <Route path="generalsetting" element={<AdminGeneralSettings />} />
          <Route path="changepass" element={<AdminChangePass />} />
          <Route path="changegmail" element={<AdminChangeGmail />} />
        </Route>

        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </>
  );
};

export default App;
