import React, { useState, useEffect } from "react";
import DataTable, { createTheme } from "react-data-table-component";
import DeletePopUp from "../../components/DeletePopUp";
import { MdDelete } from "react-icons/md";
import { ToastContainer, toast } from 'react-toastify';
import { useGetAllContactUsQuery,useDeleteContactByIdMutation } from "../../services/profile";
import {getToken} from '../../services/localStorage';
import Loader from "../../components/Loader";





//Custom Theme eg:  theme="solarized"
createTheme(
  "solarized",
  {
    text: {
      primary: "#268bd2",
      secondary: "#2aa198",
    },
    background: {
      default: "#002b36",
    },
    context: {
      background: "#cb4b16",
      text: "#FFFFFF",
    },
    divider: {
      default: "#073642",
    },
    action: {
      button: "rgba(0,0,0,.54)",
      hover: "rgba(0,0,0,.08)",
      disabled: "rgba(0,0,0,.12)",
    },
  },
  "dark"
);

const ContactDataPage = () => {
  const [_id, setId] = useState();
  const [showMyModal, setShowMyModal] = useState(false);
  const token = getToken('token');
  const {data,isLoading} = useGetAllContactUsQuery(token)
  const [deleteContact] = useDeleteContactByIdMutation()
    const [fullName, setFullName] = useState("");
    const [email, setEmail] = useState("");

  const handleDelete = async(_id)=>{
    setShowMyModal(true)
    setId(_id)
  }
  const DeleteId = ()=>{
    const res=  deleteContact({_id,token})
    toast("res.data.message")
   }
   const handleOnClose = (_id) => {
     setShowMyModal(false)
   };


  const columns = [
    {
      name: "Full Name",
      selector: (row) => <div className="text-admin-primary">{row.fullName}</div>,
      sortable: true,
      style: {
        color: "yellow",
        fontSize: "14px",
        fontWeight: 400,
      },
    },
  
    {
      name: "Phone Number",
      selector: (row) => row.phoneNumber,
    },
    {
      name: "city",
      selector: (row) => row.city,
    },
    {
      name: "message",
      selector: (row) => row.message,
    },
    {
      name: "Action",
      cell: (row) => (
        <button
          onClick={() => handleDelete(row?._id)}
          className="btn border bg-red-300 text-black flex items-center justify-center gap-2 hover:scale-105 duration-300 text-sm hover:bg-red-600"
        >
          
          Delete <MdDelete className="text-red-950 " />
        </button>
      ),
      button: true,
    },
  ];

  
  // const [filteredData, setFilteredData] = useState([]); // For Filtering The data

  // Filter
  // useEffect(() => {
  //   // const result = data?.data.filter((value) => {
  //   const result = data?.filter((value) => {
  //     return (
  //       value?.fullName.toLowerCase().match(fullName.toLowerCase()) &&
  //       value?.email.toLowerCase().match(email.toLowerCase())
  //     );
  //   });
  //   setFilteredData(result);
  // }, [fullName, email]);

  const filteredData = data?.filter(
    (item) =>
      item.fullName.toLowerCase().includes(fullName.toLowerCase()) &&
      item.fullName.toString().includes(fullName.toLowerCase())
  );

  // Expandable Row Component
  const ExpandedComponent = ({ data }) => (
    <>
      {/* <pre>{JSON.stringify(data, null, 2)}</pre> */}
      <div className="grid p-4 gap-1 grid-cols-1 md:grid-cols-3 text-sm bg-black rounded-2xl m-auto duration-300 w-11/12 text-white ">
        <div className="flex gap-3 items-center">
          name :<span className="text-xl">{data.fullName}</span>
        </div>

        <div className="flex gap-3 items-center">
          email :<span className="text-xl">{data.email}</span>
        </div>
        <div className="flex gap-3 items-center">
          {/* name :<span className="text-xl">{data.fullName}</span> */}
        </div>
  
        <div className="flex gap-3 items-center">
          city :<span className="text-xl">{data.city}</span>
        </div>
        <div className="flex gap-3 items-center">
          message :<span className="text-xl">{data.message}</span>
        </div>
        <div className="flex gap-3 items-center">
        phoneNumber :<span className="text-xl">{data.phoneNumber}</span>
        </div>
        
      </div>
    </>
  );

  return (
    <>
    <ToastContainer />
    {isLoading?<Loader size = "24" color = "blue" content = "kvk"/>:<div>
        <div className="bg-admin-primary  h-full text-text_blue p-4 w-full  ">
          <div className="grid">
            <DataTable
              columns={columns}
              data={filteredData}
              pagination
              title="Contact Form Data"
              fixedHeader
              fixedHeaderScrollHeight="53vh"
              // dense
              highlightOnHover // When we Hover we can highlight the rows
              subHeader
              subHeaderComponent={
                <div className="grid grid-cols-2 gap-2">
                  <input
                    className="rounded p-1 focus:scale-x-105 duration-300"
                    type="text"
                    placeholder="search..."
                    value={fullName}
                    onChange={(e) => setFullName(e.target.value)}
                  />
                </div>
              }
              subHeaderAlign="right"
              expandableRows
              expandableRowsComponent={ExpandedComponent}
              
              // customStyles={customStyles} // custom style
              theme="solarized" // Custom Theme
            />
          </div>
        </div>
      </div>}
      
      <DeletePopUp onClose={handleOnClose} visible={showMyModal} DeleteId={DeleteId} />
      

      {/* <div className="bg-black h-[100vh] z-[1000]">
        <DeletePopUp />
      </div> */}
    </>
  );
};

export default ContactDataPage;
