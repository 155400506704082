import React, { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { IoIosArrowUp } from "react-icons/io";
import { BiMessageRounded } from "react-icons/bi";
import { Link } from "react-router-dom";

const MainPage = () => {
  const [visible, setVisible] = useState(false); //For to Scroll To Top
  const [sticky, setSticky] = useState(false);

  // Toggle To Top

  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > 300) {
      setVisible(true);
    } else if (scrolled <= 300) {
      setVisible(false);
    }
  };

  window.addEventListener("scroll", toggleVisible);

  // on render, set listener Check To header to become Sticky or fixed
  useEffect(() => {
    window.addEventListener("scroll", isSticky);
    return () => {
      window.removeEventListener("scroll", isSticky);
    };
  }, []);

  const isSticky = () => {
    const scrollTop = window.scrollY;
    const stickyClass = scrollTop >= 60 ? true : false;
    setSticky(stickyClass);
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <>
      <div className="flex flex-col justify-between min-h-screen">
        <Header sticky={sticky} />
        <Outlet />
        <Footer />
      </div>

      {/* This will Move This in Top */}
      <div
        onClick={scrollToTop}
        className="fixed bottom-5 bg-black right-5 z-[100] text-white text-5xl font-black/70 rounded-full "
      >
        <span>
          <IoIosArrowUp />
        </span>
      </div>

      <Link to="/contact">
        <div className="fixed  bottom-20 right-5 z-[100] text-white text-3xl hover:text-5xl duration-500 font-black bg-gradient-to-r from-primary to-secondary p-2 rounded-full ">
          <span>
            <BiMessageRounded />
          </span>
        </div>
      </Link>
    </>
  );
};

export default MainPage;
