import React from "react";
import HomeAboutUs from "../components/HomeAboutUs";

const About = () => {
  return (
    <>
      <div>
        <HomeAboutUs />
      </div>
    </>
  );
};

export default About;
