import React from "react";
import { Link } from "react-router-dom";
import { FaArrowAltCircleRight } from "react-icons/fa";
import { MdEmail } from "react-icons/md";
import { IoLocation } from "react-icons/io5";
import { FaFacebookSquare } from "react-icons/fa";
import { IoLogoWhatsapp } from "react-icons/io";
import { FaSquareInstagram } from "react-icons/fa6";
const Footer = () => {
  return (
    <>
      <div
        className=" text-white flex flex-col gap-5 justify-center items-center min-h-[60vh] 
       bg-gradient-to-r from-primary via-secondary to-ternary rounded-sm  pt-8 "
      >
        <div className=" w-10/12 m-auto bg-black py-12 rounded-xl ">
          <div className="grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3  gap-12 w-10/12 m-auto  ">
            <div className="flex flex-col gap-3">
              <h5 className="text-2xl">Quick Links</h5>
              <ul className="space-y-1">
                <Link className="flex items-center gap-2">
                  <FaArrowAltCircleRight />
                  <Link to="/">Home</Link>
                </Link>

                <Link className="flex items-center gap-2 ">
                  <FaArrowAltCircleRight />
                  <Link to="/about">About us</Link>
                </Link>

                <Link className="flex items-center gap-2">
                  <FaArrowAltCircleRight />
                  <Link to="/service">Services</Link>
                </Link>

                <Link className="flex items-center gap-2 ">
                  <FaArrowAltCircleRight />
                  <Link to="/process">Process</Link>
                </Link>

                <Link className="flex items-center gap-2 ">
                  <FaArrowAltCircleRight />
                  <Link to="/contact">Contact us</Link>
                </Link>
              </ul>
            </div>

            <div className="flex flex-col gap-3">
              <h5 className="text-2xl">Address</h5>
              <div>
                <div className="flex items-center gap-2">
                  <IoLocation className="text-xl" />
                  <p className="font-heading">Bhopal</p>
                </div>
                <p className="font-heading">Madhya Pradesh</p>
              </div>
            </div>

            <div className="flex flex-col gap-3 col-span-2 md:col-span-1 lg:col-span-1 ">
              <h5 className="text-2xl">Contact Us</h5>

              <div className="flex items-center  gap-2">
                <MdEmail className="text-xl" />
                <a
                  className=" text-xl text-white tracking-wide"
                  href="mailto:kvkadvisories@gmail.com"
                >
                  <u className="text-lg"> kvkadvisories@gmail.com</u>
                </a>
              </div>
              <div className="flex gap-2 text-xl  ">
                <FaFacebookSquare />
                <FaSquareInstagram />
                <IoLogoWhatsapp />
                <MdEmail />
              </div>
              <img src="KVK Logo (1).svg" className="h-20 w-20" alt="" />
            </div>
          </div>
        </div>
        <div className="bg-black h-12 flex justify-end items-center mt-5 w-full">
          <div className="w-11/12 m-auto">
            <p className="text-white/80 font-subheading">
              Copyright@2024{" "}
              <a
                target="_blank"
                className=" text-xl text-white tracking-wide"
                href="https://www.onlinehoja.com"
              >
                <u className="text-lg"> onlinehoja</u>
              </a>
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
