import React from "react";
import HomeBasic from "../components/HomeBasic";
import TogglePage from "../components/TogglePage";

const Service = () => {
  return (
    <>
      <div>
        <TogglePage />
        <HomeBasic />
      </div>
    </>
  );
};

export default Service;
