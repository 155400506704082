import React from "react";
import { SiPaytm } from "react-icons/si";
import { FaGooglePay } from "react-icons/fa";
import { IoQrCodeSharp } from "react-icons/io5";
import { useGetBarCodeQuery } from "../services/profile";
import { getToken } from "../services/localStorage";
import Loader from "./Loader";

const QRcodePage = () => {
  const token = getToken("token");
  const { data, isLoading } = useGetBarCodeQuery(token);
  console.log(data, "8");
  return (
    <>
      {isLoading ? (
        <Loader size="24" color="blue" content="kvk" />
      ) : (
        <div className="grid md:grid-cols-2 grid-cols-1 min-h-screen  bg-primary justify-items-center items-center gap-4 p-4  bg-gradient-to-r from-primary via-secondary to-ternary ">
          <div className="text-white flex flex-col items-center md:items-start gap-4 border p-5 bg-black/40 border-slate-300/30 rounded-2xl shadow-4xl shadow-black hover:scale-110 duration-300">
            <h3>KVK ADVISORYS</h3>
            <div>
              <div className="flex items-center gap-2 text-2xl justify-center md:justify-start">
                <span>
                  <IoQrCodeSharp />
                </span>
                <span>Receive Money</span>
              </div>
              <div className="flex items-center gap-3">
                <span className="text-sm">From any UPI app</span>

                <span>
                  <img
                    className="h-12 w-16 "
                    src="https://download.logo.wine/logo/PhonePe/PhonePe-Logo.wine.png"
                    //  src={`http://localhost:7001/${data?.qrcode}`} alt="product pic"
                  />
                </span>
                <span>
                  <SiPaytm className="text-4xl" />
                </span>
                <span>
                  <FaGooglePay className="text-4xl" />
                </span>
                <span>BHIM</span>
              </div>
            </div>
            <div className="flex flex-col text-center md:text-left text-2xl">
              <span>UPI IDs and Numbers</span>
              <span className="font-light flex gap-1 items-start">
                <span>
                  <IoQrCodeSharp />
                </span>
                <span>UPI ID :</span>
                {data?.upiId}
              </span>
            </div>
          </div>
          <div className=" order-first md:order-none ">
            <img
              className=" h-auto w-96  "
              src={`https://backend.kvkadvisories.com/${data?.qrcode}`}
              // src="https://www.business2community.com/wp-content/uploads/2012/04/Picture-21.png"
              alt=""
            />
          </div>
        </div>
      )}
    </>
  );
};

export default QRcodePage;
