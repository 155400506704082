import React, { useEffect } from "react";
import { motion } from "framer-motion";
import { useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import Expert from "../assets/Experts-bro.svg";
import Honest from "../assets/Honesty-bro.svg";
import Risk from "../assets/Risk management-bro.svg";
import Plans from "../assets/Business Plan-bro.svg";
import Calm from "../assets/Relaxing at home-rafiki.svg";
import Peace from "../assets/Peace of mind-pana.svg";

import { Link } from "react-router-dom";
// import { useGetTechnoQuery } from "../services/profile";
import { BsArrowDownShort } from "react-icons/bs";

const Advantages = () => {
  //   const { data, isLoading } = useGetTechnoQuery();
  const { ref, inView } = useInView({
    threshold: 0.2,
  });
  const animation = useAnimation();

  useEffect(() => {
    if (inView) {
      animation.start({
        x: 0,
        opacity: 1,
        transition: {
          type: "spring",
          duration: 1,
          bounce: 0.3,
        },
      });
    }
    if (!inView) {
      animation.start({
        x: "-100vh",
        opacity: 0,
      });
    }
  }, [animation, inView]);

  return (
    <>
      <motion.div className="flex min-h-screen  items-center justify-center bg-primary flex-col py-20 mb-20  ">
        <div className="flex flex-col text-center justify-center items-center  py-10 w-9/12 m-auto gap-2">
          <h3 className=" text-3xl font-thin  text-white  text-center">
            Maximize Your{" "}
            <i className="text-text_blue font-bold">Investments</i>
          </h3>
          <p className="text-white/80 text-xl font-light ">
            Why Trusting a Stock Advisor is Your Winning Strategy
          </p>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5 ">
          {/* Block Chain */}

          <div className="group border border-white/20  items-center relative justify-center overflow-hidden cursor-pointer hover:shadow-xl hover:shadow-black/30 transition-shadow">
            <div className="h-96 w-72 ">
              <img
                src={Expert}
                className="h-full w-full object-cover group-hover:rotate-3 group-hover:scale-125 transition-transform duration-500"
                alt=""
              />
            </div>

            <div className="absolute inset-0 bg-gradient-to-b from-transparent via-transparent to-black group-hover:from-black/70 group-hover:via-black/60 group-hover:to-black/70"></div>

            <div className="absolute inset-0 flex flex-col items-center justify-center px-9 text-center translate-y-[60%] group-hover:translate-y-0 transition-all ">
              <h1 className="text-2xl font-bold text-white">Expert Guidance</h1>
              <p className="text-lg italic text-white mb-3 opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                Get advice from experienced professionals who know how to make
                smart investment choices.
              </p>
            </div>
          </div>
          <div className="group border border-white/20  items-center relative justify-center overflow-hidden cursor-pointer hover:shadow-xl hover:shadow-black/30 transition-shadow">
            <div className="h-96 w-72 ">
              <img
                src={Risk}
                className="h-full w-full object-cover group-hover:rotate-3 group-hover:scale-125 transition-transform duration-500"
                alt=""
              />
            </div>

            <div className="absolute inset-0 bg-gradient-to-b from-transparent via-transparent to-black group-hover:from-black/70 group-hover:via-black/60 group-hover:to-black/70"></div>

            <div className="absolute inset-0 flex flex-col items-center justify-center px-9 text-center translate-y-[60%] group-hover:translate-y-0 transition-all ">
              <h1 className="text-2xl font-bold text-white">Reduced Risk</h1>
              <p className="text-lg italic text-white mb-9 opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                Experts help spread your investments to lower the chances of
                losing money.
              </p>
            </div>
          </div>
          <div className="group border border-white/20  items-center relative justify-center overflow-hidden cursor-pointer hover:shadow-xl hover:shadow-black/30 transition-shadow">
            <div className="h-96 w-72 ">
              <img
                src={Plans}
                className="h-full w-full object-cover group-hover:rotate-3 group-hover:scale-125 transition-transform duration-500"
                alt=""
              />
            </div>

            <div className="absolute inset-0 bg-gradient-to-b from-transparent via-transparent to-black group-hover:from-black/70 group-hover:via-black/60 group-hover:to-black/70"></div>

            <div className="absolute inset-0 flex flex-col items-center justify-center px-9 text-center translate-y-[60%] group-hover:translate-y-0 transition-all ">
              <h1 className="text-2xl font-bold text-white">
                Personalized Plans
              </h1>
              <p className="text-lg italic text-white mb-12 opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                Get a customized investment strategy that fits your goals and
                comfort level with risk.
              </p>
            </div>
          </div>
          <div className="group border border-white/20  items-center relative justify-center overflow-hidden cursor-pointer hover:shadow-xl hover:shadow-black/30 transition-shadow">
            <div className="h-96 w-72 ">
              <img
                src={Honest}
                className="h-full w-full object-cover group-hover:rotate-3 group-hover:scale-125 transition-transform duration-500"
                alt=""
              />
            </div>

            <div className="absolute inset-0 bg-gradient-to-b from-transparent via-transparent to-black group-hover:from-black/70 group-hover:via-black/60 group-hover:to-black/70"></div>

            <div className="absolute inset-0 flex flex-col items-center justify-center px-9 text-center translate-y-[60%] group-hover:translate-y-0 transition-all ">
              <h1 className="text-2xl font-bold text-white">
                Honest and Clear
              </h1>
              <p className="text-lg italic text-white mb-12 opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                They're honest about fees and performance, so you know what
                you're getting.
              </p>
            </div>
          </div>
          <div className="group border border-white/20  items-center relative justify-center overflow-hidden cursor-pointer hover:shadow-xl hover:shadow-black/30 transition-shadow">
            <div className="h-96 w-72 ">
              <img
                src={Calm}
                className="h-full w-full object-cover group-hover:rotate-3 group-hover:scale-125 transition-transform duration-500"
                alt=""
              />
            </div>

            <div className="absolute inset-0 bg-gradient-to-b from-transparent via-transparent to-black group-hover:from-black/70 group-hover:via-black/60 group-hover:to-black/70"></div>

            <div className="absolute inset-0 flex flex-col items-center justify-center px-9 text-center translate-y-[60%] group-hover:translate-y-0 transition-all ">
              <h1 className="text-2xl font-bold text-white">Stay Calm</h1>
              <p className="text-lg italic text-white mb-10 opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                They'll keep you from making rash decisions when the market gets
                rocky.
              </p>
            </div>
          </div>
          <div className="group border border-white/20  items-center relative justify-center overflow-hidden cursor-pointer hover:shadow-xl hover:shadow-black/30 transition-shadow">
            <div className="h-96 w-72 ">
              <img
                src={Peace}
                className="h-full w-full object-cover group-hover:rotate-3 group-hover:scale-125 transition-transform duration-500"
                alt=""
              />
            </div>

            <div className="absolute inset-0 bg-gradient-to-b from-transparent via-transparent to-black group-hover:from-black/70 group-hover:via-black/60 group-hover:to-black/70"></div>

            <div className="absolute inset-0 flex flex-col items-center justify-center px-9 text-center translate-y-[60%] group-hover:translate-y-0 transition-all ">
              <h1 className="text-2xl font-bold text-white">Peace of Mind</h1>
              <p className="text-lg italic text-white mb-1 opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                With a professional at your side, you can relax knowing your
                investments are in good hands.
              </p>
            </div>
          </div>
        </div>
        <div className="w-full flex justify-center items-center py-12 ">
          <Link to="/contact">
            <button className="px-4 py-3 border flex items-center gap-1 rounded-full text-xl text-white hover:bg-[#111111] group">
              Contact Us
              <BsArrowDownShort className="text-3xl group-hover:-translate-y-1 duration-500" />
            </button>
          </Link>
        </div>
      </motion.div>
    </>
  );
};

export default Advantages;
