import React from "react";
// import safe from "../Assets/Safe.jpg";
import future from "../assets/future service.jpg";

const FutureService = () => {
  return (
    <>
      {/* Future service */}
      <div className="md:w-10/12 w-11/12 m-auto py-6">
        <div className=" grid grid-cols-1 items-center md:grid-cols-2 gap-16 min-h-[65vh]  bg-black text-white rounded-2xl ">
          <div className="space-y-3 flex flex-col justify-center items-start p-4  ">
            <h3 className=" font-heading">Future Service</h3>
            <p className=" font-body leading-7 ">
              In the stock market, "stock futures" or "stock index futures" are
              financial contracts that allow traders and investors to speculate
              on the future direction of stock prices or stock market indexes.
            </p>
          </div>

          <img
            src={future}
            className=" w-full h-full order-first md:order-none rounded-2xl"
          />
        </div>
      </div>
    </>
  );
};

export default FutureService;
