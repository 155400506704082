import React from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import FormikControl from "./ReusableFormField/FormikControl";
import { ToastContainer, toast } from "react-toastify";

import { useAddContactUsMutation } from "../services/profile";
const HomeContactUs = () => {
  const initialValues = {
    fullName: "",
    email: "",
    phoneNumber: "",
    city: "",
    message: "",
  };
  const [addcontact] = useAddContactUsMutation();
  const onSubmit = async (values, onSubmitProps) => {
    const res = await addcontact(values);

    toast(res.data.message);
    onSubmitProps.setSubmitting(false);
    if (res.data.status == "success") {
      onSubmitProps.resetForm();
    }
  };
  const validationSchema = Yup.object({
    fullName: Yup.string().min(3).max(40).required("Required"),
    email: Yup.string().email("Invalie email formate").required("Required"),
    phoneNumber: Yup.string()
      .required("Mobile number is required")
      .matches(/^[6-9]\d{9}$/, "Invalid mobile number"),
    city: Yup.string()
      .required("City is required")
      .max(50, "City name must be at most 50 characters long"),
  });
  return (
    <>
      <ToastContainer />
      <div className="min-h-screen bg-gradient-to-r from-primary via-secondary to-ternary py-6 pb-10">
        <div className="flex flex-col gap-3 p-4 text-white justify-center items-center font-light text-xl">
          <h3 className="text-4xl">Contact Us</h3>
          <div> Elevate Your Financial Journey with Us!"</div>
          <h2 className="text-text_blue font-bold">
            "Unlock Your Financial Potential – Reach Out and Let's Get Started!"
          </h2>
          <p className="text-sm">
            Have any question? We'd love to hear from you. 👍
          </p>
        </div>

        {/* Form */}
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={validationSchema}
          validateOnMount
        >
          {(formik) => {
            return (
              <Form className="grid gap-3 md:w-6/12 sm:w-8/12 w-11/12 m-auto bg-white shadow-xl shadow-black p-6 rounded-3xl">
                <FormikControl
                  control="input"
                  type="text"
                  label="Full Name"
                  name="fullName"
                  valid={formik.errors.fullName && formik.touched.fullName}
                  placeholder="Enter Your FullName"
                />
                <FormikControl
                  control="input"
                  type="email"
                  label="Email"
                  name="email"
                  valid={formik.errors.email && formik.touched.email}
                  placeholder="Enter Your Email"
                />
                <FormikControl
                  control="input"
                  type="number"
                  label="Phone Number"
                  name="phoneNumber"
                  valid={
                    formik.errors.phoneNumber && formik.touched.phoneNumber
                  }
                  placeholder="Enter Your Number"
                />

                <FormikControl
                  control="input"
                  type="text"
                  label="Your City"
                  name="city"
                  valid={formik.errors.city && formik.touched.city}
                  placeholder="Enter Your City"
                />

                <FormikControl
                  control="textarea"
                  type="text"
                  label="Your message"
                  name="message"
                  valid={formik.errors.message && formik.touched.message}
                  placeholder="Enter Your Message"
                />

                <button
                  className="px-4 py-2 rounded bg-text_blue active:text-white disabled:bg-red-500 disabled:cursor-not-allowed"
                  disabled={!formik.isValid || formik.isSubmitting}
                  type="submit"
                >
                  Submit
                </button>
              </Form>
            );
          }}
        </Formik>
      </div>
    </>
  );
};

export default HomeContactUs;
