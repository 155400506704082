import React, { useState } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import FormikControl from "../../components/ReusableFormField/FormikControl";
import { Link, Outlet } from "react-router-dom";

const AdminLoginPage = () => {
  const [formKey, setFormKey] = useState(0);
  const [hide, setHide] = useState(true);

  const initialValues = {
    userName: "",
    pass: "",
  };
  const onSubmit = (values, onSubmitProps) => {
    console.log("From Data", values);
    console.log("Saved Data", JSON.parse(JSON.stringify(values)));
    onSubmitProps.setSubmitting(false);
    onSubmitProps.resetForm();
    setFormKey((prevKey) => prevKey + 1);
  };
  const validationSchema = Yup.object({
    userName: Yup.string().min(3).max(30).required("Required"),
    pass: Yup.string()
      .required("Password is required")
      .min(8, "Password must be at least 8 characters")
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@#$%^&!])[A-Za-z\d@#$%^&!]{8,}$/,
        "Password must contain at least one uppercase letter, one lowercase letter, one digit, and one special character."
      ),
  });

  const handleVisible = () => {
    setHide(!hide);
  };

  return (
    <>
      <div className="flex justify-center items-center min-h-screen bg-gradient-to-r from-primary via-secondary to-ternary  font-subheading ">
        <Outlet />
      </div>
    </>
  );
};

export default AdminLoginPage;
