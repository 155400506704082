import React, { useState } from "react";
import { GiArrowsShield } from "react-icons/gi";
import { FaSackDollar } from "react-icons/fa6";
import { FaBalanceScaleLeft } from "react-icons/fa";
import MCX from "../assets/mcx services.jpg";
const MCXService = () => {
  const [open1, setOpen1] = useState(false);
  return (
    <>
      <div className=" py-8 flex flex-col  ">
        <div className=" space-y-12 w-11/12 m-auto ">
          <div className="bg-[#302452] p-4 shadow-2xl shadow-black grid justify-items-start gap-2 my-16">
            <div className="border border-green-700 bg-green-700 h-1 rounded-full w-7 "></div>
            <h3>Mcx Service</h3>
            <p className="font-light">
              1. Cash refers to the money available for buying or selling
              shares, also known as "free cash" or "available funds." It's
              crucial for investors as it enables them to seize opportunities
              and cover losses during market declines.
            </p>

            <div className={` leading-7 font-body ${open1 ? "" : "hidden"}`}>
              <div className="flex flex-col gap-4">
                <p className="font-light">
                  2. At KVK Advisories, we prioritize transparency by providing
                  accurate performance data, instilling confidence in
                  decision-making. Our research covers gold, silver, base
                  metals, and crude oil services in commodity trading calls.
                </p>

                <p className="font-light">
                  3. The MCX service facilitates the trading of commodity
                  futures and options contracts on the exchange. These contracts
                  entail agreements to buy or sell a specific quantity of a
                  commodity at a predetermined price and future date. Trading
                  commodity derivatives on MCX allows market participants to
                  hedge against price volatility, speculate on price movements,
                  and manage exposure to various commodities.
                </p>
                <p className="font-light">
                  4. MCX offers a transparent and regulated marketplace,
                  allowing electronic commodity trading. The exchange provides a
                  robust trading platform, risk management systems, and
                  efficient settlement procedures. Operating during specified
                  trading hours, MCX follows strict rules and regulations to
                  ensure fair trading practices and investor protection.
                </p>
                <p className="font-light">
                  5. To access the MCX service, individuals and institutions
                  must open trading accounts with authorized brokers or exchange
                  members. These brokers act as intermediaries, facilitating
                  trade execution, providing market information, and offering
                  advisory services. In summary, the MCX service empowers market
                  participants to engage in commodity trading, enabling them to
                  capitalize on commodity price movements and manage risk
                  exposure effectively.
                </p>
              </div>
            </div>
            {open1 ? (
              <button
                onClick={() => setOpen1(!open1)}
                className={`btn text-white border border-black/25 bg-gradient-to-tr 
                  from-primary to-secondary ${open1 ? "block" : "hidden"}`}
              >
                Read Less
              </button>
            ) : (
              <button
                onClick={() => setOpen1(!open1)}
                className={`btn text-white border border-black/25 bg-gradient-to-tr 
                from-primary to-secondary ${open1 ? "hidden" : ""}`}
              >
                Read More
              </button>
            )}
          </div>

          <div className=" bg-black/70 border border-slate-300/20  shadow-black shadow-2xl py-6 px-7 rounded-md space-y-12">
            <div className="space-y-3 py-4">
              <h3 className=" font-medium font-heading text-center text-white">
                Here are some of the benefits of trading MCX at KVK
              </h3>

              <div className=" grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-5 min-h-[40vh] text-white">
                <div
                  className=" bg-pink-700 rounded-xl flex flex-col gap-3 justify-center items-center p-7 shadow-md
               hover:scale-105 duration-300"
                >
                  <GiArrowsShield className=" text-6xl" />
                  <p className=" text-center text-xl">
                    The ability to hedge against gold price fluctuations
                  </p>
                </div>

                <div
                  className=" bg-green-600 rounded-xl flex flex-col gap-3 justify-center items-center p-7 shadow-md
               hover:scale-105 duration-300"
                >
                  <FaSackDollar className=" text-6xl" />
                  <p className=" text-center text-xl">
                    The potential for high profits
                  </p>
                </div>

                <div
                  className=" bg-blue-700 rounded-xl flex flex-col gap-3 justify-center items-center p-7 shadow-md
               hover:scale-105 duration-300"
                >
                  <FaBalanceScaleLeft className=" text-6xl" />
                  <p className=" text-center text-xl">
                    The ability to trade on a margin basis
                  </p>
                </div>
              </div>

              {/* <div className=" min-h-[45vh] grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-5 py-8 ">
                <div
                  className=" relative  border border-white/10 shadow 
               hover:-translate-y-6 duration-500 rounded-md"
                >
                  <img
                    src="https://wallpapers.com/images/hd/trading-wallpaper-ynfqhj74ml8p96ca.jpg"
                    className="w-full h-[45vh] rounded-md"
                  />
                  <div className=" absolute bottom-0 bg-black/50 text-white p-3">
                    <p className=" font-subheading text-center">
                      The ability to hedge against gold price fluctuations
                    </p>
                  </div>
                </div>

                <div
                  className=" relative  border border-white/10 shadow 
               hover:-translate-y-6 duration-500 rounded-md"
                >
                  <img
                    src="https://wallpapers.com/images/hd/trading-wallpaper-ynfqhj74ml8p96ca.jpg"
                    className="w-full h-[45vh] rounded-md"
                  />
                  <div className=" absolute bottom-0 bg-black/50 text-white p-3">
                    <p className=" font-subheading text-center">
                      The potential for high profits.
                    </p>
                  </div>
                </div>

                <div
                  className=" relative  border border-white/10 shadow 
               hover:-translate-y-6 duration-500 rounded-md"
                >
                  <img
                    src="https://wallpapers.com/images/hd/trading-wallpaper-ynfqhj74ml8p96ca.jpg"
                    className="w-full h-[45vh] rounded-md"
                  />
                  <div className=" absolute bottom-0 left-0 w-full bg-black/50 text-white p-3 ">
                    <p className=" font-subheading text-center">
                      The ability to trade on a margin basis.
                    </p>
                  </div>
                </div>
              </div> */}
            </div>

            <div className=" grid grid-cols-1 md:grid-cols-2 min-h-[60vh] gap-4 text-white">
              <div className="flex flex-col gap-3 justify-center p-3">
                <h6 className=" font-subheading">
                  Here are some of the factors that can affect the price of MCX
                  KVK:
                </h6>
                <div className="space-y-3">
                  <div className="space-y-1 font-body">
                    <p>
                      {" "}
                      1. Economic data, such as interest rates and inflation
                    </p>

                    <p>2. Weather conditions, such as droughts or floods</p>

                    <p>3. Political events, such as wars or elections</p>

                    <p>4. Supply and demand for gold</p>
                  </div>
                  <p>
                    Investors who trade MCX at KVK should carefully monitor
                    these factors and make their trading decisions based on
                    sound analysis.
                  </p>
                </div>
              </div>

              <div>
                <div>
                  <img src={MCX} className="h-80 w-full rounded-md" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MCXService;
