import React, { useState } from "react";
// import cash from "../Assets/cashe.jpg";
// import safe from "../Assets/Safe.jpg";
// import share from "../Assets/Shares.jpg";
import speculation from "../assets/speculation.jpg";
import hedging from "../assets/hedging.jpg";
import portfolio from "../assets/portfolio insurance.jpg";
const IndexService = () => {
  return (
    <>
      <div className=" py-8 flex flex-col   ">
        <div className=" space-y-16 md:w-10/12 w-11/12 m-auto ">
          <div className="space-y-4 text-white">
            <h3 className="font-heading">Index Service</h3>
            <p className=" font-body leading-7 ">
              In the share market, an index is a statistical measure that tracks
              the performance of a group of assets, such as stocks or bonds.
              Indexes are used to measure the overall health of a market or
              sector, and to compare the performance of different investments.
            </p>
          </div>

          <div className="space-y-6">
            <div className=" space-y-4 text-white">
              <h3 className="font-subheading text-2xl">Index option</h3>
              <p className=" font-body leading-7 ">
                Index option is a financial instrument that gives the buyer the
                right, but not the obligation, to buy or sell an underlying
                index at a specified price on or before a specified date. The
                underlying index can be a stock index, such as the S&P 500, or a
                commodity index, such as the CRB Index.
              </p>
            </div>

            <div
              className="space-y-16 bg-black/70 text-white  rounded-md
            px-7 py-10"
            >
              <h6 className="font-subheading font-medium">
                Index options are traded on exchanges, and they can be used for
                a variety of purposes, including:
              </h6>

              <div className=" space-y-12">
                <div className=" grid grid-cols-1 md:grid-cols-2 gap-6">
                  <div>
                    <img
                      src={speculation}
                      className="w-full h-full rounded-md"
                    />
                  </div>
                  <div className="flex flex-col gap-5 justify-center p-4">
                    <h5 className="font-heading">Speculation</h5>
                    <p className="font-body leading-7">
                      Investors can use index options to speculate on the future
                      direction of the underlying index. For example, if an
                      investor believes that the S&P 500 will rise in the
                      future, they can buy a call option on the S&P 500. If the
                      S&P 500 does rise, the investor will make a profit on the
                      call option. Hedging: Investors can use index options to
                      hedge against risk. For example, if an investor owns a
                      portfolio of stocks that is heavily invested in the
                      technology sector, they can buy a put option on the
                      technology sector index. This will protect the investor
                      from losses if the technology sector index falls.
                    </p>
                  </div>
                </div>

                <div className=" grid grid-cols-1 md:grid-cols-2 gap-6">
                  <div className=" flex flex-col gap-5 justify-center p-4">
                    <h5 className="font-heading">Hedging</h5>
                    <p className="font-body leading-7">
                      Investors can use index options to hedge against risk. For
                      example, if an investor owns a portfolio of stocks that is
                      heavily invested in the technology sector, they can buy a
                      put option on the technology sector index. This will
                      protect the investor from losses if the technology sector
                      index falls.
                    </p>
                  </div>

                  <div className="order-first md:order-none">
                    <img src={hedging} className="w-full h-full rounded-md" />
                  </div>
                </div>

                <div className=" grid grid-cols-1 md:grid-cols-2 gap-6">
                  <div>
                    <img src={portfolio} className="w-full h-full rounded-md" />
                  </div>
                  <div className="flex flex-col gap-5 justify-center p-4">
                    <h5 className="font-heading">Portfolio insurance</h5>
                    <p className="font-body leading-7">
                      Investors can use index options to create a portfolio
                      insurance strategy. This strategy involves buying a put
                      option on a broad market index, such as the S&P 500, and
                      selling call options on individual stocks. This strategy
                      will protect the investor from losses if the market
                      declines, while still allowing them to participate in the
                      upside potential of the market. Index options are a
                      complex financial instrument, and they should only be used
                      by investors who understand the risks involved.
                    </p>
                  </div>
                </div>
              </div>

              <div className="space-y-12">
                <h6 className="font-subheading font-medium">
                  Here are some of the key features of index options
                </h6>

                <div className=" grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-5 min-h-[90vh]">
                  <div
                    className=" border border-white/15 p-6 flex flex-col justify-center items-center gap-3 
                     bg-green-900 text-white rounded-md hover:-translate-y-5 duration-300
                  shadow-md"
                  >
                    <h6 className="font-medium font-heading">
                      Underlying index
                    </h6>
                    <p className=" text-center">
                      The underlying index is the asset that the index option is
                      based on. For example a call option on the S&P 500 index
                      gives the holder the right to buy the S&P 500 index at a
                      specified price on or before a specified date.
                    </p>
                  </div>

                  <div
                    className=" border border-white/15 p-6 flex flex-col  items-center gap-3 rounded-md
                  shadow-md bg-yellow-950 text-white hover:-translate-y-5 duration-300"
                  >
                    <h6 className="font-medium font-heading">Strike price</h6>
                    <p className=" text-center">
                      The strike price is the price at which the underlying
                      index can be bought or sold under the terms of the index
                      option.
                    </p>
                  </div>

                  <div
                    className=" border border-white/15 p-6 flex flex-col  items-center gap-3 rounded-md
                  shadow-md bg-pink-950 text-white hover:-translate-y-5 duration-300"
                  >
                    <h6 className="font-medium font-heading">
                      Expiration date
                    </h6>
                    <p className=" text-center">
                      The expiration date is the date on which the index option
                      expires.
                    </p>
                  </div>

                  <div
                    className=" border border-white/15 p-6 flex flex-col  items-center gap-3 rounded-md
                  shadow-md bg-orange-950 text-white hover:-translate-y-5 duration-300"
                  >
                    <h6 className="font-medium font-heading">Premium</h6>
                    <p className=" text-center">
                      The premium is the price that is paid for the index
                      option.
                    </p>
                  </div>

                  <div
                    className=" border border-white/15 p-6 flex flex-col  items-center gap-3 rounded-md
                  shadow-md bg-purple-950 text-white hover:-translate-y-5 duration-300"
                  >
                    <h6 className="font-medium font-heading">
                      Margin requirement
                    </h6>
                    <p className=" text-center">
                      The margin requirement is the amount of money that must be
                      deposited with the broker in order to purchase an index
                      option.
                    </p>
                  </div>
                </div>

                <p className=" text-center">
                  Index options can be a valuable tool for investors who want to
                  speculate on the future direction of the market, hedge against
                  risk, or create a portfolio insurance strategy. However, they
                  are a complex financial instrument, and they should only be
                  used by investors who understand the risks involved.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default IndexService;
