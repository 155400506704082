import React from "react";

const Loader = ({ size = 24, color = "blue", content = "" }) => {
  return (
    <>
      <div className="flex justify-center items-center h-screen">
        <div
          className={`animate-spin rounded-full h-${size} w-${size} border-t-2 border-b-2 border-${color}-900 flex justify-center items-center relative`}
        >
          <span className="font-bold text-blue-600 absolute">{content}</span>
        </div>
      </div>
    </>
  );
};

export default Loader;
