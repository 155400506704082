import React, { useState } from "react";
import { BsCashCoin } from "react-icons/bs";
import CashService from "./CashService";
import OptionService from "./OptionService";
import FutureService from "./FutureService";
import IndexService from "./IndexService";
import MCXService from "./MCXService";
import { GiWallet, GiChoice } from "react-icons/gi";
import { FaArrowUpRightDots } from "react-icons/fa6";
import { GiForwardField } from "react-icons/gi";
import { SiElement } from "react-icons/si";
const TogglePage = () => {
  const [toggleState, setToggleState] = useState(1);

  const toggleTab = (index) => {
    setToggleState(index);
  };
  return (
    <>
      <div className="bg-gradient-to-r from-primary via-secondary to-ternary min-h-screen text-white p-4">
        <div className="grid lg:grid-cols-5 md:grid-cols-3 grid-cols-2  gap-3 text-sm mt-10">
          <button
            className={`${
              toggleState === 1 ? "active-tabs" : "tabs"
            } p-2 px-5 border border-purple-600 uppercase rounded hover:scale-105 duration-300 font-semibold text-xs sm:text-sm md:text-base bg-black/30 `}
            onClick={() => toggleTab(1)}
          >
            <div className="flex flex-col gap-3 items-center p-2">
              <GiWallet className="text-3xl  text-admin-primary " />
              Cash Service
            </div>
          </button>
          <button
            className={`${
              toggleState === 2 ? "active-tabs" : "tabs"
            } p-2 px-5 border border-green-600 uppercase hover:scale-105 duration-300 font-semibold text-xs sm:text-sm md:text-base bg-black/30`}
            onClick={() => toggleTab(2)}
          >
            <div className="flex flex-col gap-3 items-center p-2">
              <GiChoice className="text-3xl text-green-600 " />
              Option Service
            </div>
          </button>

          <button
            className={`${
              toggleState === 3 ? "active-tabs" : "tabs"
            } p-2 px-5 border border-red-600 uppercase hover:scale-105 duration-300 font-semibold text-xs sm:text-sm md:text-base bg-black/30`}
            onClick={() => toggleTab(3)}
          >
            <div className="flex flex-col gap-3 items-center p-2">
              <GiForwardField className="text-3xl text-red-600 " />
              Future
            </div>
          </button>

          <button
            className={`${
              toggleState === 4 ? "active-tabs" : "tabs"
            } p-2 px-5 border border-orange-500 uppercase hover:scale-105 duration-300 font-semibold text-xs sm:text-sm md:text-base bg-black/30`}
            onClick={() => toggleTab(4)}
          >
            <div className="flex flex-col gap-3 items-center p-2">
              <FaArrowUpRightDots className="text-3xl text-orange-500 " />
              index
            </div>
          </button>
          <button
            className={`${
              toggleState === 4 ? "active-tabs" : "tabs"
            } p-2 px-5 border border-pink-500 uppercase hover:scale-105 duration-300 font-semibold text-xs sm:text-sm md:text-base bg-black/30`}
            onClick={() => toggleTab(5)}
          >
            <div className="flex flex-col gap-3 items-center p-2">
              <SiElement className="text-3xl text-pink-500 " />
              MCX
            </div>
          </button>
        </div>

        <div className="my-10  h-full">
          <div
            className={`${toggleState === 1 ? "block" : "hidden"} text-white`}
          >
            <CashService />
          </div>
          <div
            className={`${toggleState === 2 ? "block" : "hidden"} text-white`}
          >
            <OptionService />
          </div>{" "}
          <div
            className={`${toggleState === 3 ? "block" : "hidden"} text-white`}
          >
            <FutureService />
          </div>{" "}
          <div
            className={`${toggleState === 4 ? "block" : "hidden"} text-white`}
          >
            <IndexService />
          </div>
          <div
            className={`${toggleState === 5 ? "block" : "hidden"} text-white`}
          >
            <MCXService />
          </div>
        </div>
      </div>
    </>
  );
};

export default TogglePage;
