import React, { useState, useRef, useEffect } from "react";
import { Link, NavLink, useFetcher, useNavigate } from "react-router-dom";
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from "react-icons/md";
import {
  AiOutlineDashboard,
  AiOutlineAreaChart,
  AiTwotoneSetting,
} from "react-icons/ai";
import { MdOutlineInventory } from "react-icons/md";
import { TiContacts } from "react-icons/ti";
import { BiRevision } from "react-icons/bi";
import { MdLogout } from "react-icons/md";

import Logo from "../../assets/KVK Logo (1).svg";
import { removeToken } from "../../services/localStorage";

const AdminSideBar = ({ drower, handleDrower }) => {
  const navigate = useNavigate();

  const handleLogout = () => {
    removeToken("token");
    navigate("/adminlogin");
  };

  const [home, setHome] = useState(false);
  const [contact, setContact] = useState(false);
  const [inventory, setInventory] = useState(false);
  const [setting, setSetting] = useState(false);

  // let domNode = useClickOutside(() => {
  //     setContact(false)
  //     console.log("one")
  // })

  return (
    <>
      {/* Nav Bar / Side Bar */}
      <nav
        className={` ${
          drower
            ? "translate-x-0 duration-300 "
            : "-translate-x-full duration-300"
        } absolute transform  inset-0 lg:relative min-h-screen z-10 w-10/12 lg:w-56 bg-admin-white_bg text-black   hover:shadow-2xl hover:border-gray-200/20 lg:translate-x-0 h-[100vh] flex-shrink-0 flex flex-col scrollbar scrollbar-none  `}
      >
        {/*Bar Header */}
        <Link
          //   to="/admindashboard"
          className=" text-black  h-[10vh] flex lg:justify-center items-center justify-between w-full"
        >
          <span className="font-bold text-xl sm:text-xl p-2  flex items-center gap-1 font-heading">
            <img src={Logo} className=" h-6 w-6" alt="" />
            <span className="font-black text-transparent inline-block bg-clip-text bg-gradient-to-r from-text_blue to-green_main">
              KVK Advisories
            </span>
          </span>
          <button className="lg:hidden" onClick={() => handleDrower()}>
            <MdKeyboardArrowLeft className="text-4xl mr-5 bg-admin-primary/30 rounded-full hover:bg-admin-primary text-white hover:scale-125 duration-300" />
          </button>
        </Link>

        {/* Bar Body Content*/}
        <ul className=" text-black flex flex-col gap-1  h-[90vh] overflow-auto px-2 text-sm  scrollbar-none ">
          {/* User Home Section */}
          <li
          // ref={domNode}
          >
            <NavLink
              className="px-4 cursor-pointer py-2 group hover:bg-admin-primary rounded-md w-full flex justify-between items-center border border-gray/20 hover:text-white"
              onClick={() => setHome(!home)}
            >
              <div className="flex items-center justify-center gap-3 ">
                <AiOutlineDashboard className="text-admin-primary group-hover:text-white text-xl" />
                Dashboard
              </div>

              <span className="group-hover:translate-x-1 duration-300">
                <MdKeyboardArrowRight
                  className={`${home ? "rotate-90 " : "null"} duration-200 `}
                />
              </span>
            </NavLink>

            <div
              className={` ${
                !home ? "hidden" : "block"
              } py-2 w-full px-3 bg-white/10 rounded-lg mt-2 bg-white_shad hover:shadow-xl hover:border-gray-200/20 `}
            >
              <div className="flex flex-col gap-2 text-sm capitalize pl-2">
                <Link
                  to="/admindashboard"
                  onClick={() => setHome(false)}
                  className="hover:bg-admin-secondary rounded py-1 px-2 hover:text-white"
                >
                  Home
                </Link>
              </div>
            </div>
          </li>

          {/* ContactUs  */}
          <li>
            <NavLink
              className="px-4 cursor-pointer py-2 group hover:bg-admin-primary rounded-md w-full flex justify-between items-center border border-gray/20 hover:text-white"
              onClick={() => setContact(!contact)}
            >
              <div className="flex items-center justify-center gap-3">
                <TiContacts className="text-admin-primary group-hover:text-white text-xl" />
                Clients
              </div>

              <span className="group-hover:translate-x-1 duration-300">
                <MdKeyboardArrowRight
                  className={`${contact ? "rotate-90 " : "null"} duration-200 `}
                />
                {/* {contact ? <MdOutlineKeyboardArrowDown /> : <MdKeyboardArrowRight />} */}
              </span>
            </NavLink>
            <div
              className={` ${
                !contact ? "hidden" : "block"
              } py-2   px-3 bg-white/10 rounded-lg mt-2 w-full bg-white_shad hover:shadow-xl hover:border-gray-200/20`}
            >
              <div className="flex flex-col gap-2 text-sm capitalize pl-2 w-full">
                <Link
                  to="kycdata"
                  onClick={() => setContact(false)}
                  className="hover:bg-admin-secondary rounded py-1 px-2 hover:text-white"
                >
                  KYC Customers
                </Link>
                <Link
                  to="contactdata"
                  onClick={() => setContact(false)}
                  className="hover:bg-admin-secondary rounded py-1 px-2 hover:text-white"
                >
                  Contacted Clients
                </Link>
                <Link
                  to="activeuser"
                  onClick={() => setContact(false)}
                  className="hover:bg-admin-secondary rounded py-1 px-2 hover:text-white"
                >
                  Active Clients
                </Link>
              </div>
            </div>
          </li>

          {/* Inventory Form */}
          <li>
            <NavLink
              className="px-4 cursor-pointer py-2 group hover:bg-admin-primary rounded-md w-full flex justify-between items-center border border-gray/20 hover:text-white"
              onClick={() => setInventory(!inventory)}
            >
              <div className="flex items-center justify-center gap-3">
                <MdOutlineInventory className="text-admin-primary group-hover:text-white text-xl" />
                Process Updates
              </div>

              <span className="group-hover:translate-x-1 duration-300">
                <MdKeyboardArrowRight
                  className={`${
                    inventory ? "rotate-90 " : "null"
                  } duration-200 `}
                />
                {/* {contact ? <MdOutlineKeyboardArrowDown /> : <MdKeyboardArrowRight />} */}
              </span>
            </NavLink>
            <div
              className={` ${
                !inventory ? "hidden" : "block"
              } py-2   px-3 bg-white/10 rounded-lg mt-2 w-full bg-white_shad hover:shadow-xl hover:border-gray-200/20`}
            >
              <div className="flex flex-col gap-2 text-sm capitalize pl-2 w-full">
                <Link
                  to="/admindashboard/updateqrcode"
                  onClick={() => setInventory(false)}
                  className="hover:bg-admin-secondary rounded py-1 px-2 hover:text-white"
                >
                  QRCode
                </Link>
              </div>
            </div>
          </li>

          {/* Setting */}
          <li>
            <NavLink
              className="px-4 cursor-pointer py-2 group hover:bg-admin-primary rounded-md w-full flex justify-between items-center border border-gray/20 hover:text-white"
              onClick={() => setSetting(!setting)}
            >
              <div className="flex items-center justify-center gap-3">
                <AiTwotoneSetting className="text-admin-primary animate-spin group-hover:text-white text-xl" />
                Setting
              </div>

              <span className="group-hover:translate-x-1 duration-300">
                <MdKeyboardArrowRight
                  className={`${setting ? "rotate-90 " : "null"} duration-200 `}
                />
                {/* {contact ? <MdOutlineKeyboardArrowDown /> : <MdKeyboardArrowRight />} */}
              </span>
            </NavLink>
            <div
              className={` ${
                !setting ? "hidden" : "block"
              } py-2   px-3 bg-white/10 rounded-lg mt-2 w-full bg-white_shad hover:shadow-xl hover:border-gray-200/20`}
            >
              <div className="flex flex-col gap-2 text-sm capitalize pl-2 w-full">
                <Link
                  to="/admindashboard/generalsetting"
                  onClick={() => setSetting(false)}
                  className="hover:bg-admin-secondary rounded py-1 px-2 hover:text-white"
                >
                  General
                </Link>
              </div>
            </div>
          </li>

          {/* Vesion */}
          <li>
            <NavLink className="px-4 cursor-pointer py-2 group hover:bg-admin-primary rounded-md w-full flex justify-between items-center border border-gray/20 hover:text-white">
              <div
                onClick={() => handleLogout()}
                className="flex items-center justify-center gap-3"
              >
                <MdLogout className="text-admin-primary group-hover:text-white text-xl" />
                Logout
              </div>

              <div></div>
            </NavLink>
          </li>
        </ul>
      </nav>
    </>
  );
};

export default AdminSideBar;
