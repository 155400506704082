import React, { useEffect } from "react";
import { useGetDashboardQuery} from "../../services/profile";
import { getToken } from "../../services/localStorage";
import { useNavigate } from "react-router-dom";
import Loader from "../../components/Loader";


const AdminHomePage = () => {
  const token =getToken("token")
  const navigate = useNavigate()
  useEffect(() => {
    if(!token){
      navigate("/adminlogin")
    }
  }, [token])
  const {data,isLoading}=useGetDashboardQuery(token)
  console.log(data,'17')
  return (
    <>{
      isLoading?<Loader/>: <div>
      <div className="z-100  ">
        <div className="flex flex-col text-center gap-10 font-bold text-xs md:text-base ">
          <ul className=" grid md:grid-cols-6 gap-5 grid-cols-3 font-bold">
            <div className="flex flex-col justify-center items-center">
              <li className="bg-[#40128B] w-full p-5 rounded text-white font-semibold flex justify-center items-center">
                {data?.ActiveUsers}
              </li>
              <h2 className="text-black/60">Active Members</h2>
            </div>

            <div className="flex flex-col justify-center items-center">
              <li className="bg-[#40128B] w-full p-5 rounded text-white font-semibold flex justify-center items-center">
              {data?.ContactUsers}
              </li>
              <h2 className="text-black/60">Enquiry Users</h2>
            </div>

            <div className="flex flex-col justify-center items-center">
              <li className="bg-[#40128B] w-full p-5 rounded text-white font-semibold flex justify-center items-center">
              {data?.InactiveUsers}
              </li>
              <h2 className="text-black/60">kyc completed clients</h2>
            </div>
            <div className="flex flex-col justify-center items-center">
              <li className="bg-[#40128B] w-full p-5 rounded text-white font-semibold flex justify-center items-center">
              {data?.InactiveUsers-data?.ActiveUsers}
              </li>
              <h2 className="text-black/60">Inactive kyc clients</h2>
            </div>
          </ul>
          
        </div>
      </div>
    </div>
    }
     
    </>
  );
};

export default AdminHomePage;
