import React, { useState } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import FormikControl from "../../components/ReusableFormField/FormikControl";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";

import {
  useAdminProfileQuery,
  useEditProfileMutation,
} from "../../services/profile";
import { getToken } from "../../services/localStorage";

// const data = {
//   userName: "Roshan Rana",
//   phNumber: "9753679813",
//   email: "ranaroshan763@gmail.com",
// };

const AdminChangeGmail = () => {
  const token = getToken("token");
  const { data } = useAdminProfileQuery(token);
  const [editProfile] = useEditProfileMutation();
  console.log(data, "18");
  const [formKey, setFormKey] = useState(0);
  const initialValues = {
    name: "",
    phoneNumber: "",
    email: "",
  };
  const onSubmit = async (values, onSubmitProps) => {
    console.log("From Data", values);
    // console.log("Saved Data", JSON.parse(JSON.stringify(values)));
    onSubmitProps.setSubmitting(false);
    const res = await editProfile({ values, token });
    console.log(res, "33");
    toast(res.data.message);
    if (res.data.status) {
      onSubmitProps.resetForm();
    }

    setFormKey((prevKey) => prevKey + 1);
  };
  const validationSchema = Yup.object({
    name: Yup.string()
      .min(3, "Username must be at least 3 characters")
      .max(20, "Username must be at most 20 characters")
      .matches(
        /^[a-zA-Z0-9_]+$/,
        "Username can only contain letters, numbers, and underscores"
      )
      .required("Username is required"),
    email: Yup.string()
      .email("Invalid email address")
      .required("Email is required"),
    phoneNumber: Yup.string()
      .required("Mobile number is required")
      .matches(/^[6-9]\d{9}$/, "Invalid mobile number"),
  });

  return (
    <>
      <ToastContainer />
      <div className="bg-white_background shadow-xl border  shadow-black  p-4 rounded-md md:w-7/12 sm:w-10/12 m-auto">
        <div className="flex  justify-between items-center border-b border-black/30 my-3 flex-col sm:flex-row p-2">
          <h3>Update Your QR Code </h3>
        </div>

        {/* Form */}
        <Formik
          initialValues={data || initialValues}
          onSubmit={onSubmit}
          validationSchema={validationSchema}
          validateOnMount
          enableReinitialize
        >
          {(formik) => {
            console.log("formik", Formik);
            return (
              <Form>
                <div className="grid grid-cols-1 gap-6">
                  {/* Image */}
                  <FormikControl
                    control="input"
                    type="text"
                    label="User Name"
                    name="name"
                    valid={formik.errors.name && formik.touched.name}
                    placeholder="UserName"
                  />
                  <FormikControl
                    control="input"
                    type="email"
                    label="Email"
                    name="email"
                    valid={formik.errors.email && formik.touched.email}
                    placeholder="Email"
                  />
                  <FormikControl
                    control="input"
                    type="number"
                    label="Phone Number"
                    name="phoneNumber"
                    valid={
                      formik.errors.phoneNumber && formik.touched.phoneNumber
                    }
                    placeholder="Phone Number"
                  />

                  <button
                    className="px-4 py-2 rounded bg-text_blue active:text-white disabled:bg-red-500 disabled:cursor-not-allowed"
                    disabled={!formik.isValid || formik.isSubmitting}
                    type="submit"
                  >
                    Update
                  </button>
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    </>
  );
};

export default AdminChangeGmail;
