import React, { useState } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import FormikControl from "../../components/ReusableFormField/FormikControl";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useSendOtpMutation } from "../../services/profile";
import { ToastContainer, toast } from "react-toastify";
import Logo from "../../assets/KVK Logo (1).svg";

const AdminMailConfirm = () => {
  const [otp, { isLoading }] = useSendOtpMutation();
  const navigate = useNavigate();
  const [formKey, setFormKey] = useState(0);
  const [hide, setHide] = useState(true);

  const initialValues = {
    email: "",
  };
  const onSubmit = async (values, onSubmitProps) => {
    console.log("From Data", values);
    const res = await otp(values);
    console.log(res.data, "23");
    if (res.data.status == "success") {
      navigate("/adminlogin/otpconfirm", { state: { email: values.email } });
      onSubmitProps.setSubmitting(false);
      onSubmitProps.resetForm();
    }
    toast(res.data.message);
  };
  const validationSchema = Yup.object({
    email: Yup.string().min(3).max(30).required("Required"),
  });

  const handleVisible = () => {
    setHide(!hide);
  };

  const optpage = () => {
    navigate("/adminlogin/otpconfirm");
  };

  return (
    <>
      <ToastContainer />
      <div className="bg-white_background shadow-xl border  shadow-black  p-4 rounded-md md:w-7/12 sm:w-10/12 m-auto">
        <div className="flex  justify-between items-center border-b border-black/30 my-3 flex-col sm:flex-row p-2">
          <span>
            <Link to="/">
              <img className="h-12 w-12 " src={Logo} alt="" />
            </Link>
          </span>
          <h3 className="text-2xl font-black text-transparent inline-block bg-clip-text bg-gradient-to-r from-text_blue to-green_main">
            KVK Advisories
          </h3>
        </div>

        {/* Form */}
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={validationSchema}
          validateOnMount
        >
          {(formik) => {
            console.log("formik", Formik);
            return (
              <Form>
                <div className="grid grid-cols-1 gap-6">
                  <FormikControl
                    control="input"
                    type="text"
                    label="Enter Your Email"
                    name="email"
                    valid={formik.errors.email && formik.touched.email}
                    placeholder="Enter Your Email ID"
                  />

                  <button
                    className="px-4 py-2 rounded bg-text_blue active:text-white disabled:bg-red-500 disabled:cursor-not-allowed"
                    disabled={!formik.isValid || formik.isSubmitting}
                    type="submit"
                  >
                    Submit
                  </button>
                </div>
              </Form>
            );
          }}
        </Formik>

        <div className="text-center py-2 gap-3 flex flex-col">
          <h2 className="text-text_blue">Check Your Mail For OTP</h2>
          <h2 className="text-red-500 text-sm">NOTE:- DO NOT SHARE YOUR OTP</h2>
        </div>
      </div>
    </>
  );
};

export default AdminMailConfirm;
