import React from "react";

const HomeBasic = () => {
  return (
    <>
      <div className="flex justify-center items-center min-h-screen bg-gray-50 relative text-center">
        <div className="sm:w-72 h-40 w-40 filter blur-xl   mix-blend-multiply absolute top-[40%]   rounded-full border sm:h-72  bg-gray-400 opacity-70 animate-bolb"></div>
        <div className="sm:w-72 h-40 w-40 filter blur-xl   mix-blend-multiply absolute top-[30%] left-[45%]   rounded-full border sm:h-72  bg-pink-400 opacity-70 animate-bolb animation-dealy-200"></div>
        <div className="sm:w-72 h-40 w-40 filter blur-xl   mix-blend-multiply absolute top-[30%] right-[45%]   rounded-full border sm:h-72  bg-yellow-400 opacity-70 animate-bolb animation-dealy-400"></div>
        <div className="  text-black">
          <div className="flex flex-col gap-4 md:w-9/12 w-11/12 m-auto">
            <h1>Our Services</h1>
            <h3 className="font-heading">
              Cash, Intraday Trading, MCX & More!
            </h3>
            <ul className="flex flex-col gap-4">
              <li>
                <span className="font-subheading text-text_blue text-lg font-semibold">
                  Unlock Opportunities :{" "}
                </span>
                Navigate the stock market with our expert services in cash
                trading, intraday strategies, MCX commodities, and beyond.
                Whether you're seeking short-term gains or long-term growth,
                we've got you covered.
              </li>
              <li>
                <span className="font-subheading text-text_blue text-lg font-semibold">
                  Invest Wisely :{" "}
                </span>
                Maximize your returns while managing risks with our
                comprehensive range of services tailored to meet your investment
                needs. From cash to options, we provide insights and guidance to
                help you make informed decisions.
              </li>
              <li>
                <span className="font-subheading text-text_blue text-lg font-semibold">
                  Start Today :{" "}
                </span>
                Take the first step towards financial success by exploring our
                services. Get in touch now to begin your journey to profitable
                trading.
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default HomeBasic;
