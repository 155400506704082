import { useEffect, useState, useRef } from "react";
import AdminSideBar from "../admincomponents/AdminSideBar";
import { RxHamburgerMenu } from "react-icons/rx";
import { MdEmail, MdOutlineHelp } from "react-icons/md";
import { AiFillBell, AiFillSetting, AiOutlineLogout } from "react-icons/ai";
import { CgProfile } from "react-icons/cg";
import { IoIosMail } from "react-icons/io";
import { FaUserEdit } from "react-icons/fa";
import { Link, Outlet, useNavigate } from "react-router-dom";
import unknown from "../../assets/pexels-rdne-stock-project-8370341.jpg";
import { removeToken, getToken } from "../../services/localStorage";

const AdminMainPage = () => {
  const token = getToken("token");
  const navigate = useNavigate();

  useEffect(() => {
    if (!token) {
      navigate("/adminlogin");
    }
  }, [token]);
  const handleLogout = () => {
    removeToken("token");
    navigate("/adminlogin");
  };

  const [drower, setDrower] = useState(false);
  const [profile, setProfile] = useState(false);

  let profileRef = useRef(null);

  const handleDrower = () => {
    setDrower(!drower);
  };

  useEffect(() => {
    let handler = (event) => {
      if (profileRef.current && !profileRef.current.contains(event.target)) {
        setProfile(false);
      }
    };
    document.addEventListener("mousedown", handler);
    return () => {
      document.removeEventListener("mousedown", handler);
    };
  });

  return (
    <>
      <div className="lg:flex antialiased min-h-screen relative bg-admin-white_bg w-[100vw] max-w-[100vw] text-black ">
        {/* Nav Bar / Side Bar */}
        <AdminSideBar drower={drower} handleDrower={handleDrower} />

        <div className="relative z-0 lg:flex-grow">
          {/* Header */}
          <header className="text-black shadow-md duration-300  gap-4 h-[10vh] flex items-center justify-between px-5 hover:shadow-lg hover:border-gray-200/20">
            <button
              onClick={(e) => setDrower(!drower)}
              className="md:text-4xl text-xl bg-admin-primary/70 rounded hover:bg-admin-primary hover:scale-125 duration-300 text-white
                             p-2  lg:hidden flex-shrink-0"
            >
              <RxHamburgerMenu />
            </button>

            <div className="md:text-xl   font-heading">Dashboard</div>

            {/* Left */}
            <div className="flex items-center gap-3 text-admin-primary ">
              <span ref={profileRef} className="shrink-0  relative z-[100]">
                <img
                  onClick={() => setProfile(!profile)}
                  className="w-6 h-6 rounded-full object-cover
                            "
                  src={unknown}
                  alt=""
                />

                <div
                  className={` ${
                    profile ? "block z-[1000] bg-white" : "hidden"
                  } absolute border z-[1000]  right-0 w-44 rounded p-4 mt-3 mr-3 bg-white_bg shadow-2xl text-black   `}
                >
                  <div className=" flex flex-col gap-3 w-full justify-center items-center ">
                    <div className="flex flex-col items-center">
                      KVK Advisories
                      <span className="text-xs text-black/40">
                        Trading Specialists
                      </span>
                      <hr className=" shadow w-full" />
                    </div>

                    <div>
                      <ul className="flex flex-col text-sm w-full ">
                        <Link
                          to="/admindashboard/profile"
                          onClick={() => setProfile(false)}
                          className="flex items-center gap-3 group cursor-pointer hover:bg-admin-primary hover:text-white w-full p-2 rounded-md "
                        >
                          <CgProfile className="text-xl text-admin-primary group-hover:-translate-x-1 duration-300 group-hover:text-white" />
                          My Profile
                        </Link>
                        <Link
                          to="/admindashboard/generalsetting"
                          className="flex items-center gap-3 group cursor-pointer hover:bg-admin-primary hover:text-white w-full p-2 rounded-md "
                        >
                          <FaUserEdit className="text-xl text-admin-primary group-hover:-translate-x-1 duration-300 group-hover:text-white" />
                          Edit Profile
                        </Link>

                        <li className="flex items-center gap-3 group cursor-pointer hover:bg-admin-primary hover:text-white w-full p-2 rounded-md ">
                          <IoIosMail className="text-xl text-admin-primary group-hover:-translate-x-1 duration-300 group-hover:text-white" />
                          Inbox
                        </li>

                        <Link to="/admindashboard/generalsetting">
                          <li className="flex items-center gap-3 group cursor-pointer hover:bg-admin-primary hover:text-white w-full p-2 rounded-md ">
                            <AiFillSetting className="text-xl animate-spin text-admin-primary group-hover:-translate-x-1 duration-300 group-hover:text-white" />
                            Setting
                          </li>
                        </Link>
                        <li className="flex items-center gap-3 group cursor-pointer hover:bg-admin-primary hover:text-white w-full p-2 rounded-md ">
                          <MdOutlineHelp className="text-xl text-admin-primary group-hover:-translate-x-1 duration-300 group-hover:text-white" />
                          Helps
                        </li>
                        <li
                          onClick={() => handleLogout()}
                          className="flex items-center gap-3 group cursor-pointer hover:bg-admin-primary hover:text-white w-full p-2 rounded-md "
                        >
                          <AiOutlineLogout className="text-xl text-admin-primary group-hover:-translate-x-1 duration-300 group-hover:text-white" />
                          Logout
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </span>
            </div>
          </header>

          {/* MainBody */}
          <div className=" h-[90vh] overflow-auto scrollbar-thumb-primary scrollbar-thin p-3 bg-admin-white_shad max-w-[calc(100% - 56px)] ">
            {/* All content Goes Here */}
            <Outlet />
          </div>
        </div>
      </div>
    </>
  );
};

export default AdminMainPage;
