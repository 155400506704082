import React, { useState, useEffect } from "react";
import { MdOutlineMenu } from "react-icons/md";
import { NavLink } from "react-router-dom";

const Header = ({ sticky }) => {
  const [menu, setMenu] = useState(false);
  const [scrollPosition, setScrollPosition] = useState(0);

  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true });

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <>
      <div
        className="h-[50px] hidden md:block text-sm    bg-gradient-to-r from-primary via-secondary
       to-ternary text-white border-b border-slate-300/10 font-subheading"
      >
        <div className="w-full h-full flex justify-between items-center text-white px-20 ">
          <div className="text-sm">
            Email :
            <a
              className=" text-sm text-white tracking-wide"
              href="mailto:kvkadvisories@gmail.com"
            >
              <u className=""> kvkadvisories@gmail.com</u>
            </a>
          </div>
          <div className="text-2xl font-black text-transparent inline-block bg-clip-text bg-gradient-to-r from-text_blue to-green_main">
            <ul>KVK Advisories</ul>
          </div>
        </div>
      </div>

      <header
        className={` ${
          sticky
            ? "sticky md:h-16  transition-all md:px-20 md:opacity-80 md:bg-black/30 z-30  border-b border-slate-300/10  "
            : null
        }  flex justify-between items-center px-4 md:px-12 h-24 w-full 
        bg-gradient-to-r from-primary via-secondary to-ternary duration-700`}
      >
        <span>
          <img
            className={`h-10 duration-300 ${scrollPosition ? "h-14 " : "h-20"}`}
            src="KVK Logo (1).svg"
            alt=""
          />
        </span>

        <nav>
          <button onClick={() => setMenu(!menu)} className="md:hidden">
            <MdOutlineMenu className="text-white text-3xl" />
          </button>
          <ul
            className={`fixed left-0 mt-3 md:mt-0 right-0 min-h-screen  text-white space-y-4 p-4  md:relative md:flex md:min-h-0 md:space-y-0 md:space-x-6 md:p-0 md:translate-x-0 duration-300 uppercase text-sm  ${
              menu
                ? "translate-x-0 bg-gradient-to-r from-primary via-secondary to-ternary"
                : "translate-x-full"
            } `}
          >
            <li className="hover:text-secondary hover:font-bold hover:text-xl duration-300">
              <NavLink onClick={() => setMenu(false)} to="/">
                Home
              </NavLink>
            </li>
            <li className="hover:text-secondary hover:font-bold hover:text-xl duration-300">
              <NavLink onClick={() => setMenu(false)} to="/about">
                About
              </NavLink>
            </li>

            <li className="hover:text-secondary hover:font-bold hover:text-xl duration-300">
              <NavLink onClick={() => setMenu(false)} to="/service">
                Service
              </NavLink>
            </li>
            <li className="hover:text-secondary hover:font-bold hover:text-xl duration-300">
              <NavLink onClick={() => setMenu(false)} to="/process">
                Process
              </NavLink>
            </li>
            <li className=" hover:scale-110 ">
              <NavLink
                className="border border-text_blue btn text-white hover:text-text_blue"
                onClick={() => setMenu(false)}
                to="/contact"
              >
                Contact
              </NavLink>
            </li>
          </ul>
        </nav>
      </header>
    </>
  );
};

export default Header;
